import React from 'react'

export default function Aboutus() {
  return (
    <main className="main about">
    <div className="page-header page-header-bg text-center" style={{background: '50%/cover #D4E1EA url("assets/images/page-header-bg.jpg")'}}>
      <div className="container">
        <h1 className=''><span>OVER ONS</span>
        ONS BEDRIJF</h1>
        {/* <a href="contact.html" className="btn btn-dark ">Contact</a> */}
      </div>{/* End .container */}
    </div>{/* End .page-header */}
    <nav aria-label="breadcrumb" className="breadcrumb-nav">
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/" onClick={() => localStorage.setItem("menuItem", "")} ><i className="icon-home" /></a></li>
          <li className="breadcrumb-item active" aria-current="page">Over Ons</li>
        </ol>
      </div>{/* End .container */}
    </nav>
    <div className="about-section">
      <div className="container">
        <h2 className="subtitle">Welkom bij Techbyte.nl - Jouw Partner in Technologie!</h2>
        <p>
          Techbyte.nl is trots onderdeel van Unity Trading B.V., een toonaangevende groothandel in consumentenelektronica gevestigd in het bruisende Amsterdam. Met een solide reputatie in de branche beheert Unity Trading B.V. succesvol grote afnemers, en nu verwelkomen we ook kleinere afnemers en winkeliers bij Techbyte.nl.
        </p>
        <p>
          Bij Techbyte.nl begrijpen we dat de wereld van consumentenelektronica snel evolueert en dat kleinere afnemers en winkeliers behoefte hebben aan een efficiënt, snel en eenvoudig inkoopproces. Daarom hebben we Techbyte.nl opgericht, een platform dat specifiek is ontworpen om aan deze behoeften te voldoen.
        </p>
        <p>
          Wij bieden een uitgebreid assortiment hoogwaardige consumentenelektronica, variërend van de nieuwste gadgets tot essentiële elektronische apparaten. Onze producten zijn zorgvuldig geselecteerd om te voldoen aan de diverse eisen van onze klanten, en we streven ernaar om de nieuwste en meest innovatieve technologieën beschikbaar te maken voor iedereen.
        </p>
        <p>
          Bij Techbyte.nl geloven we in eenvoud en gemak. Daarom hebben we een gebruiksvriendelijk platform gecreëerd waarop kleinere afnemers en winkeliers zich snel kunnen aanmelden en moeiteloos hun bestellingen kunnen plaatsen. Ons team van professionals staat altijd klaar om u te ondersteunen en u te voorzien van de beste service.
        </p>

        <p>
          Of u nu op zoek bent naar de nieuwste smartphones, tablets, accessoires of andere consumentenelektronica, Techbyte.nl is uw betrouwbare partner in technologie. Ontdek het gemak van online bestellen en profiteer van onze snelle levering en concurrerende prijzen.
        </p>
        <p>
        Bij Techbyte.nl streven we ernaar om uw ervaring met consumentenelektronica inkoop te vereenvoudigen en te verbeteren. Word vandaag nog lid van onze groeiende gemeenschap en ontdek de wereld van mogelijkheden die Techbyte.nl te bieden heeft!
        </p>
      </div>{/* End .container */}
    </div>{/* End .about-section */}
    <div className="features-section bg-gray">
      <div className="container">
        <h2 className="subtitle">WAAROM VOOR ONS KIEZEN</h2>
        <div className="row">
          <div className="col-lg-4 abt-fer-fix">
            <div className="feature-box bg-white">
              <i className="icon-shipped" />
              <div className="feature-box-content p-0">
                <h3>Geen verzendkosten</h3>
                <p style={{textAlign:"justify"}}>
                  Bij Techbyte.nl geloven wij in snel en zorgeloos winkelen.
                  Daarom bieden wij gratis verzending aan op al onze producten.
                  Met onze speciale bestelwagen staat uw bestelling bij u
                  binnen slechts 1 tot 2 werkdagen bij u thuis. Geen verborgen kosten of lange wachttijden.
                  Gratis verzending is niet zomaar een aanbieding, het is onze toewijding om een ​​naadloze winkelervaring te bieden.
                  Plaats vandaag nog uw bestelling op Techbyte.nl en geniet van het gemak van gratis en snelle levering.
                  Uw tevredenheid is onze prioriteit en we zijn er om uw winkelervaring op Techbyte.nl zo aangenaam mogelijk te maken
                  soepel mogelijk.</p>
              </div>{/* End .feature-box-content */}
            </div>{/* End .feature-box */}
          </div>{/* End .col-lg-4 */}
          <div className="col-lg-4 abt-fer-fix">
            <div className="feature-box bg-white">
              <i className="icon-us-dollar" />
              <div className="feature-box-content p-0">
                <h3>100% terugbetaling</h3>
                <p style={{textAlign:"justify"}}>
                Bij Techbyte.nl willen we dat u volledig tevreden bent met uw aankoop. Als u om welke reden dan ook niet tevreden bent met uw bestelling, kunt u deze binnen 30 dagen na ontvangst retourneren.
                Ons retourproces is eenvoudig en probleemloos: neem contact op met ons klantenserviceteam via <strong> info@techbyte.nl </strong> om de terugkeer in gang te zetten.
                Verpak het artikel veilig, inclusief alle originele verpakkingen en accessoires. Stuur het artikel naar ons terug met behulp van een traceerbare verzendmethode naar keuze.
                Zodra we uw retourzending hebben ontvangen en het artikel hebben geïnspecteerd, zullen we uw terugbetaling of omruiling verwerken. Houd er rekening mee dat artikelen in de originele staat moeten zijn en dat de verzendkosten niet worden gerestitueerd. Wij streven ernaar om retourzendingen zo snel mogelijk te verwerken, doorgaans binnen 7 werkdagen.
                Als u vragen heeft of hulp nodig heeft, aarzel dan niet om contact op te nemen met ons vriendelijke klantenserviceteam.
                Uw tevredenheid is belangrijk voor ons en we willen het retourproces zo eenvoudig mogelijk maken.
                </p>
              </div>{/* End .feature-box-content */}
            </div>{/* End .feature-box */}
          </div>{/* End .col-lg-4 */}
          <div className="col-lg-4 abt-fer-fix">
            <div className="feature-box bg-white">
              <i className="icon-online-support" />
              <div className="feature-box-content p-0">
                <h3>Online-ondersteuning 24/7</h3>
                <p style={{textAlign:"justify"}}>
                Bij Techbyte.nl streven we ernaar u uitzonderlijke ondersteuning te bieden wanneer u die nodig heeft. Ons toegewijde ondersteuningsteam is beschikbaar tijdens kantooruren, van maandag tot en met vrijdag, van 9.00 uur tot 17.00 uur.                
                <br></br><strong>Contactopties:</strong>
                <br></br>
                <strong>Telefonische ondersteuning: </strong> Heeft u een vraag of heeft u hulp nodig? U kunt ons tijdens onze openingstijden telefonisch bereiken op 085 - 016 13 60. Onze vriendelijke experts staan ​​klaar om u te helpen.
                <br></br><strong>E-mail ondersteuning: </strong>Voor vragen of hulp buiten onze telefonische ondersteuningstijden kunt u ons altijd een e-mail sturen via <strong>info@techbyte.nl</strong>. Wij streven ernaar om e-mails snel en efficiënt te beantwoorden.
                Wij zijn er om ervoor te zorgen dat uw Techbyte.nl-ervaring soepel en plezierig verloopt. Aarzel niet om contact op te nemen; we zijn slechts een telefoontje of e-mail verwijderd!
                </p>
              </div>{/* End .feature-box-content */}
            </div>{/* End .feature-box */}
          </div>{/* End .col-lg-4 */}
        </div>{/* End .row */}
      </div>{/* End .container */}
    </div>{/* End .features-section */}
  
    <div className="counters-section bg-gray">
      <div className="container">
      </div>{/* End .container */}
    </div>{/* End .counters-section */}
  </main> 
  )
}
