import {combineReducers} from 'redux';
import AuthReducer from './AuthReducer';  
import CategoryReducer from './CategoryReducer';  
import {
      addToCartReducer,
      getUserCartReducer,
      removeItemFromCartReducer,
      emptyCartReducer
} from "../reducer/cartReducers";

const rootReducer=combineReducers({
      productData:AuthReducer, 
      category:CategoryReducer,

      
      addedToCart: addToCartReducer,
      getUserCart: getUserCartReducer,
      removedFromCart: removeItemFromCartReducer,
      emptyCart: emptyCartReducer,
});

export default rootReducer;
