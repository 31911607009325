import React, { useState } from 'react' 
import Backdrop from '@mui/material/Backdrop';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Alert} from 'react-bootstrap';
import Config from '../../config.json'

export default function ForgetPass(props) {

	const [loading,setLoading] = useState(false);
	async function forgetUser(credentials) {
	const url = process.env.REACT_APP_BASE_URL + 'forgot-password';
	return fetch(url , {
	method: 'POST',
	headers: {
		'Content-Type': 'application/json'
	},
	body: JSON.stringify(credentials)
	})
	.then((data) => data.json())
}
//const classes = useStyles();
const [email, setUserName] = useState();
const [AlertText, setAlertText] = useState();
const [Variant, setVariant] = useState("");

const handleSubmit = async e => {
	e.preventDefault();
	setLoading(true)
	const response = await forgetUser({
	email
	});
	if (response.success == 1) {
		setAlertText(response.message);
		setVariant("success");
		setLoading(false)
	}
	else{
		setAlertText(response.message);
		setVariant("danger");
		setLoading(false)
	}
}
              
return ( 
	<main className="main">
		<div className="page-header">
			<div className="container d-flex flex-column align-items-center">
				<nav aria-label="breadcrumb" className="breadcrumb-nav">
					<div className="container">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><a href="demo4.html">Home</a></li>
							<li className="breadcrumb-item active" aria-current="page">
							Wachtwoord Vergeten
							</li>
						</ol>
					</div>
				</nav>

				<h1>Wachtwoord Vergeten</h1>
			</div>
		</div>

		<div className="container reset-password-container">
			<div className="row">
				<div className="App col-md-12">  
					<Alert variant={Variant} show={AlertText? true : false}>
					{AlertText}
					</Alert> 
				</div>
				<div className="col-lg-6 offset-lg-3">
					<div className="feature-box border-top-primary">
					<div className="feature-box-content">
					<form className="mb-0" action="#" onSubmit={handleSubmit}>
						<p>
							Wachtwoord vergeten? Vul hier uw gebruikersnaam of e-mail adres in. U krijgt een mail met een link waarmee u een nieuwe wachtwoord kunt aanmaken.
						</p>
						<div className="form-group mb-0">
							<label for="reset-email" className="font-weight-normal">Email</label>
							<input
								required
								fullWidth
								id="email"
								name="email"
								label="Email Address"
								type="email"
								className="form-input form-wide mb-2"
								onChange={e => setUserName(e.target.value)}
							/>
						</div>

						<div className="form-footer mb-0">
							<a href="/login">Klik hier om in te loggen</a>
							<button type="submit" 
							className="btn btn-md btn-primary form-footer-right font-weight-normal text-transform-none mr-0">
								Indienen
							</button>
						</div>
						<div className="form-footer mb-0">
						{ loading ? 
						<>
						...even geduld. Herstel link wordt gestuurd&nbsp;<img src={Config.REACT_BASE_URL+'/custom/assets/images/loading.gif'} style={{width:'35px',height:'35px'}}/>
						</>
						:''}
						</div>
					</form>
					</div>
					</div>
				</div>
			</div>
		</div>
	</main>
)
}
		