import React, { useEffect, useState } from 'react';
import Loader from '../common/Loader';
import { Link } from "react-router-dom"; 
import axiosIntance from '../../helper/axios';
import Config from "../../config.json"

export default function CategoryLists(){

    const [loader, setLoader] = useState(false);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        fetchCategories();
      }, []);

    const fetchCategories = async (e) => {
        setLoader(true);
        await axiosIntance.get(`${process.env.REACT_APP_BASE_URL}get-categories`).then(response=>{
            setCategoryList(response.data?.body);
            setLoader(false)
        }).catch(err=>{

        })
      }

    const categoryListHandler = (slug, id) => {
        localStorage.removeItem('selectcat');
        localStorage.setItem('selectcat', slug);
        window.location.href = "/products";
    }

    return(
        <>
        <main className="main">
        <div className="container">
        <Loader loading={loader} />
        <div className='row accessries mb-5'>
        { categoryList.length> 0 ?
            categoryList?.map((cat) => {
                return(
                cat?.subCategory.map((subCat,sIndex)=>{
              return(
                  <div className="product-default inner-quickview inner-icon quantity-input col-3 card mt-5" style={{borderRadius:"10px",maxWidth:"265px"}} key={sIndex}>
                      <figure>
                          <Link onClick={() => categoryListHandler(subCat.slug, subCat._id)}>
                              <img src={`${subCat?.image}`} width="280" height="280" alt="product"/>
                          </Link>
                      </figure>
                      <div className="product-details">
                        
                          <h3 className="product-title"> <Link onClick={() => categoryListHandler(subCat.slug, subCat._id)}>{subCat?.name}</Link> </h3>
                           
                          <div className="price-box">
                              <span className="product-price">
                           
                              </span>
                          </div>
                         
                      </div>
                  </div>
              )}) )}) : 
                <>
              
                        <div className="col-10 mx-auto card-ordersuccess">
                                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/nf.webp`} alt=''/>
                                <h3>Sorry, we kunnen het niet vinden</h3>
                        </div>
                
                </>
                }
        </div>
      </div>

        </main>
        </>
    )
}