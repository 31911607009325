import React from 'react';
import Config from "../../config.json"

export default function NotFoundPage() {

    return (
        <>

            <div className="container ">
                        <div className="row ordersuccess ">
                            <div className="col-10 mx-auto card-ordersuccess">
                                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/nfpage.png`} alt=''/>
                                <p className="error-explanation text-center">
                                    Het spijt ons, maar de pagina die u zocht bestaat niet
                                </p>
                            </div>
                        </div>
                </div>
        </>
    )
}