import React, { useState, useEffect, StrictMode } from 'react'
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, } from 'react-redux';
import { getUserCart, removeItemFromCart } from '../../action/cartActions';
import { removeLocalStorage, removeCookies } from "../common/utils.js";
// import Googletranslation from './Googletranslation';
import { GET } from '../../helper/service';
import axiosIntance from '../../helper/axios';

async function logout(credentials) {
  const url = process.env.REACT_APP_BASE_URL + 'logout';
  return axiosIntance.post(url, credentials)
    .then(data => data)
}
export default function Header() {

  const searchParams = new URLSearchParams(document.location.search)
  const searchTextVal  = searchParams.get('q');
  const searchCatId = searchParams.get('cat');

  const user = JSON.parse(localStorage.getItem('user'));
  const menuItem = localStorage.getItem("menuItem");
  const navigate = useNavigate();

  const [categoryList, setCategoryList] = useState(null);
  const dispatch = useDispatch();
  const userCartState = useSelector(state => state?.getUserCart);
  const updatedCart = useSelector(state => state?.removedFromCart);
  const [token, setToken] = useState(user?.token);
  const [searchCategoryFilter, setSearchCategoryFilter] = useState("");
  const [searchText, setSearchText] = useState(searchTextVal);
  const [selectedCat, setSelectedCat] = useState(searchCatId);
  
  const displayWidth = window.innerWidth;
  const displayWidthState = displayWidth < 767 ? true : false;

  const fetchCategoryList = async () => {
    const data = await GET(`${process.env.REACT_APP_BASE_URL}get-categories`);
    setCategoryList(data);
  }

  useEffect(() => {
    dispatch(getUserCart());
  }, [updatedCart]);

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const removeItemFromCartHandler = (id) => {
    dispatch(removeItemFromCart(id));
  }

  const [select, setSelected] = useState('');
  const [optionList, setOptionList] = useState([]);

  const handleLogout = async () => {

    const response = await logout(token);
    if (response.data.success == 1) {
      removeLocalStorage();
      removeCookies();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("user");
      localStorage.removeItem("userType");
      localStorage.removeItem("customerLevelName");
      window.location.href = "/login";
    }
  };

 
  const categoryListHandler = (slug, id) => {
    localStorage.removeItem('selectcat');
    localStorage.setItem('selectcat', slug);
    window.location.href = "/products";
  }
  const [menuactive, setMenuActive] = useState(null)
  const productsListHandler = (item) => {
    localStorage.removeItem('selectcat');
    localStorage.removeItem("menuItem");
    setMenuActive(item)                      
    localStorage.setItem("menuItem", item);    
    if (menuactive == '/') {
      navigate('/');
    }
    else { navigate('/' + item); }
  }

  const menuitemactive = (item) => {
    localStorage.removeItem("menuItem");
    setMenuActive(item);
    localStorage.setItem("menuItem", item);
    if (menuactive == '/') {
      navigate('/');
    }
    else { navigate('/' + item); }
  }
      const activeStyle = { color: '#308B7B' };
      var totalProductPrice = userCartState?.cart?.items?.reduce((accum,item) => accum + parseFloat(item.price * item.quantity), 0)
 
      var totalQuantity = userCartState?.cart?.totalQuantity;
     if(localStorage.getItem('totalCartQty')!=null){

      if(localStorage.getItem('totalCartQty')!=0){
        var totalQuantity = localStorage.getItem('totalCartQty');
      }else{
        var totalQuantity = userCartState?.cart?.totalQuantity
      }
    }else{
      var totalQuantity = userCartState?.cart?.totalQuantity
    }

  return (
    <>  
      <header className="header">
        <div className="header-top text-white bg-primary">
          <div className="container-fluid"> 
              <div className="header-contact d-flex align-items-center">
                <i className="icon-phone-2" />
                <h6 className="line-height-1 pr-2">BEL NU MET ONS
                  <a href="tel:#" className="d-block text-dark font1">085 016 13 60</a>
                </h6>
              </div>
            <div className="header-right"> 
              <div className="header-icon header-search header-search-inline header-search-category">
                <a href="#" className="search-toggle" role="button">
                  <i className="icon-magnifier" />
                </a>
                <form action="/products" method="get">
                  <div className="header-search-wrapper">
                    <input type="search" value={searchText} className="form-control" name="q" id="q" placeholder="Zoeken..." required onChange={(e) => {
                        setSearchText(e.target.value); 
                      }}/>
                    <div className="select-custom body-text">
                      <select id="cat-1" name="cat" value={selectedCat}   onChange={(e) => {
                        setSearchCategoryFilter(e.target.value); 
                        setSelectedCat(e.target.value);
                      }} >
                        <option value="" >Alle Categorieen</option>
                        {
                          categoryList ? categoryList?.body?.map((list, i) => {
                            return (
                              <>
                                <optgroup label={list?.name} key={i}>
                                  {
                                    list ? list?.subCategory?.map?.((subList, id) => {
                                      return (
                                        <option value={subList.slug} key={id}>                                 
                                            {subList.name}                                          
                                        </option>
                                      )
                                    }) : null
                                  }
                                </optgroup>
                              </>
                            )
                          }) : null
                        }
                      </select>
                    </div>
                    {/* End .select-custom */}
                    <button className="btn icon-magnifier p-0" type="submit" />
                  </div>
                  {/* End .header-search-wrapper */}
                </form>
              </div>
              <span className="separator" />
              <div className="header-dropdown dropdown-expanded mx-5 px-1">
                <a href="#"><i className="fas fa-bars" /></a>
                <div className="header-menu">
                  <ul>
                      <li className='dropdown-menu-item'>
                        <Link to="/categories">Categorieen</Link>
                      </li>
                      <li className='dropdown-menu-item'>
                        <Link to="/products">Producten</Link>
                      </li>
                    {(user?.token ? <>
                      <li>
                        <Link to="/my-account">Mijn Account</Link>
                      </li>
                    </> : <>
                      <li></li>
                    </>)}
                    <li>
                      <Link to="/about-us">Over Ons</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact</Link>
                    </li>
                    <li>
                      <Link to="/wishlist">Verlanglijst</Link>
                    </li>
                    <li>
                      <Link to="/cart">Winkelwagen</Link>
                    </li>
                    {(user?.token ? <>
                      <li>
                        <a className="login-link2" onClick={handleLogout} style={{cursor:"pointer"}}>Uitloggen</a>
                      </li>
                    </> : <>
                      <li>
                        <a href="/login" className="login-link2" >Log in</a>
                      </li>
                      <li className='dropdown-menu-item'>
                        <a href="/register" className="login-link2" >Klant Worden</a>
                      </li>
                    </>)}
                  </ul>
                </div>
                {/* End .header-menu */}
              </div>
              {/* End .header-dropown */}
              <span className="separator" />
              {/* <div className="social-icons d-flex">
                <a href="https://www.facebook.com/profile.php?id=61552883525559" className="social-icon social-facebook icon-facebook" target="_blank" />
                <a href="https://www.instagram.com/tvgigant/" className="social-icon social-instagram icon-instagram" target="_blank" />
              </div> */}
              {/* End .social-icons */}
            </div>
            {/* End .header-right */}
          </div>
          {/* End .container */}
        </div>
        {/* End .header-top */}
        <div className="header-middle sticky-header bg-primary d-lg-block" data-sticky-options={`{'mobile': ${displayWidthState+""}}`}>
          <div className="container-fluid">
            {/* <div className="header-left w-lg-max ml-auto ml-lg-0">
                            
            </div> */}
            {/* End .header-left */}
            <div className="header-left order-first order-lg-0 ml-0 ml-lg-auto">
              {/* <button className="mobile-menu-toggler" type="button">
                <i className="fas fa-bars" />
              </button> */}
              <Link to="/" className="logo">
                <img src={process.env.PUBLIC_URL + "/custom/assets/images/logo-white.png"} alt="techbyte logo" width={202} height={80} />
              </Link>
            </div>
            <div>
            <nav className="main-nav d-flex w-lg-max justify-content-center virtical-scrollbar">
              <ul className="menu sf-js-enabled sf-arrows">
                <li className={(menuactive == '' && 'active') || (menuItem == "" && 'active')}>
                  <a onClick={() => menuitemactive('')} id={" "}>Home</a>
                </li>
                <li>
                  <a href='/categories' className='sf-with-ul'>Categorieen</a>
                  <ul className="">
                  {
                    categoryList ? categoryList?.body?.map((list, i) => {
                    return (
                    <>
                    <li key={i}>
                      <Link to="#" className="nolink">{list?.name}</Link>
                      {list.subCategory.length != '' ?
                        <ul style={{overflowY:'auto',height:"auto"}}>

                          { list ? list?.subCategory?.map?.((subList, id) => {
                            return (
                            <>
                              <li key={id}>
                              <Link onClick={() => categoryListHandler(subList.slug, subList._id)} >
                                {subList.name}
                              </Link>
                              </li>
                            </>
                            )} ):""}
                        </ul>
                        :""}
                    </li>
                    </>
                    )} ) : '' }
                  </ul>
                </li>
                <li className={(menuactive == 'products' && 'active') || (menuItem == "products" && 'active')}>
                  <a onClick={() => productsListHandler('products')} id='products'>
                    Producten
                  </a>  
                </li>  
                {(user?.token ? <>
                  <li className={(menuactive == 'my-account' && 'active') || (menuItem == "my-account" && 'active')}>
                    <a onClick={() => menuitemactive('my-account')} id={"my-account"}>Mijn Account</a>
                  </li>
                </> : <>
                  <li className={(menuactive == 'register' && 'active') || (menuItem == "register" && 'active')}>
                    <a onClick={() => menuitemactive('register')} id={"register"}>Klant Worden</a>
                  </li>
                </>)} 
              </ul>
            </nav>
            </div>

            {/* End .header-center */}
            <div className="header-right w-sm-max ml-0 ml-lg-auto">
              {/* <div className="header-contact d-none d-lg-flex align-items-center ml-auto mr-4">
                <i className="icon-phone-2" />
                <h6 className="line-height-1 pr-2">BEL NU MET ONS
                  <a href="tel:#" className="d-block text-dark font1">085 016 13 60</a>
                </h6>
              </div> */}
              {/* End .header-contact */}
              {(user?.token ? <>
                <a href="/my-account" className="header-icon  pl-1">
                  <i className="icon-user-2" />
                </a>
              </> : <>
                <a href="/login" className="header-icon  pl-1">
                  <i className="icon-user-2" />
                </a>
              </>)}
              <a href="/wishlist" className="header-icon btn-wishlist pl-1 pr-2">
                <i className="icon-wishlist-2" />
              </a>

              <div className="dropdown cart-dropdown">
                <a href="/cart" title="Cart">
                  <i className="minicart-icon" />
                  <span className="cart-count badge-circle">{(totalQuantity ? totalQuantity : 0)}</span>
                </a>
                {/* <div className="cart-overlay" /> */}
                {/* <div className="dropdown-menu mobile-cart">
                  <a href="#" title="Close (Esc)" className="btn-close">×</a>
                  <div className="dropdownmenu-wrapper custom-scrollbar">
                    <div className="dropdown-cart-header">Winkelwagen</div>
                    <div className="dropdown-cart-products">

                      {
                        userCartState ? userCartState?.cart?.items?.map((cartList, i) => {
                          return (
                            <div className="product" key={i}>
                              <Link to={`/product-details/${cartList.slug}`} className="product-image">
                              <div className="product-details">
                                <h4 className="product-title">
                                  <a href="#">{cartList.product}</a>
                                </h4>
                                <span className="cart-product-info">
                                  <span className="cart-product-qty">{cartList.quantity}</span> × {process.env.REACT_APP_CURRENCY}{cartList.price}
                                </span>
                              </div>
                              </Link>
                              <figure className="product-image-container">
                              <Link to={`/product-details/${cartList.slug}`} className="product-image">
                                  <img src={cartList.imgPath} alt="product" width={80} height={80} />
                                </Link>
                                <a href="#" className="btn-remove" title="Remove Product" onClick={() => removeItemFromCartHandler(cartList.id)}>
                                  <span>×</span>
                                </a>
                              </figure>
                            </div>
                          )
                        }) : null
                      }

                    </div>
                    {userCartState?.cart?.items?.length != '' ?

                      <div className="dropdown-cart-total">
                        <span>Totaal:</span>
                        <span className="cart-total-price float-right">{process.env.REACT_APP_CURRENCY}{totalProductPrice}</span>
                      </div>
                      :
                      <div className='cart-empty'>
                        <h3>Winkelwagen is leeg</h3>
                      </div>
                    }

                    {userCartState?.cart?.items?.length != '' ?
                      <div className="dropdown-cart-action">
                        <a href="/cart" className="btn btn-gray btn-block view-cart">Bekijk Winkelwagen
                        </a>
                      </div>
                      :
                      null
                    }
                  </div>
                </div> */}
                {/* End .dropdown-menu */}
              </div>
              {/* End .dropdown */}
            </div>
            {/* End .header-right */}
          </div>
          {/* End .container */}
        </div> 
      </header>
      {/* End .header */}
    </>
  )
}
