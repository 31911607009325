import React from 'react';

export default function DeleteConfirmModal ( { onClose, oStatus } ) {

    function closeModal ( result ) {
        onClose( result );
    }

    return(
        <>
            <div className="modal fade" id="orderDeleteModalCenter" tabindex="-1" role="dialog" aria-labelledby="orderDeleteModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">Bestelling verwijderen</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {oStatus ?
                      oStatus 
                      :
                        'Wilt u de bestelling verwijderen?'
                      }
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>closeModal(false)}>Annuleren</button>
                      {oStatus ? '' 
                      :
                      <button type="button" className="btn btn-primary" onClick={()=>closeModal(true)} >Bevestigen</button>
                      }
                      </div>
                  </div>
                </div>
            </div>
        </>
    )
}