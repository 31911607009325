import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { POST } from '../../helper/service';
import Config from '../../config.json';
import './invoice.css';
import moment from 'moment/moment';

export default function InvoiceDetails() {
    const params = useParams();
    // const url = process.env.REACT_APP_BASE_URL + 'order-details';
    const [orderList, setOrderList] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [thkItems, setTHKItems] = useState([]);
    
    useEffect(()=>{
        
        const url = process.env.REACT_APP_BASE_URL + 'order-details';
        const data = {
          "orderId": params.id
        }
        POST(url, data).then(response => {
          setOrderList(response?.body[0]);
          setOrderItems(response?.body[0].orderItems);
          setTHKItems(response?.body[0].thkItems);
        }).catch((error)=>{ 
          console.log("There is something wrong")
        })
    

    },[]);


return(
<>

<div class="invoice-4 invoice-content">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="invoice-inner" id="invoice_wrapper">
                    <div class="invoice-top">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="logo">
                                    <img src={`${Config.ICON_URL}/icon/tboriginal.png`} alt="logo"/>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="invoice" style={{textAlign:"right"}}>
                                    <h1 style={{color:"#308B7B"}}>Factuur</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-titel" >
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="invoice-number" >
                                    <h3>Factuurnummer: #{orderList?.orderId}</h3>
                                </div>
                            </div>
                            <div class="col-sm-6 text-end">
                                <div class="invoice-date">
                                    <h3 style={{textAlign:"end"}}>Factuurdatum: {moment(orderList?.createdAt).format('LL')}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-info">
                        <div class="row">
                            <div class="col-sm-6 mb-30">
                                <div class="invoice-number">
                                    <p class="invo-addr-1">
                                        <strong>{orderList?.companyName}</strong> <br/>
                                        {orderList?.address}
                                        {orderList?.city}, {orderList?.state}
                                        {orderList?.zipcode}
                                        {orderList?.countryName} <br/>
                                    </p>
                                </div>
                            </div>
                            <div class="col-sm-6 mb-30">
                                <div class="invoice-number text-end" style={{textAlign:"end"}}>
                                    <h4 class="inv-title-1">Afleveradres</h4>
                                    <p class="invo-addr-1">
                                        {orderList?.shippingAddress}  <br/>
                                        {orderList?.shippingCity}, {orderList?.shippingState} <br/>
                                        {orderList?.shippingCountryName} - {orderList?.shippingZipcode} <br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 mb-30">
                                <strong>Bestelstatus</strong><span style={{marginLeft:"42px", marginRight:"12px"}}>:</span><span>
                                {(()=>{
                                    if(orderList?.orderStatus == 1){
                                        return ( 'Besteld' )
                                    }
                                    if(orderList?.orderStatus == 7){
                                        return ( 'In de wacht' )
                                    }else{
                                        return ( 'Geannuleerd' )
                                    }
                                    })
                                ()}
                                </span><br/>
                                <strong>Betaalmethode</strong><span style={{marginLeft:"25px", marginRight:"12px"}}>:</span><span>{orderList?.paymentMode == 2 ? 'Bankoverschrijving' : 'iDeal'}</span><br/>
                                <strong>Verkoper</strong><span style={{marginLeft:"65px", marginRight:"12px"}}>:</span><span>Farshaaz Kasim</span>
                            </div>
                            <div class="col-sm-6 mb-30 company-iban">
                                <strong>IBAN nr</strong><span className="iban">:</span><span>NL10INGB0008299160</span><br/>
                                <strong>Rekening Naam</strong><span className="rek">:</span><span>Unity Trading BV</span><br/>
                                <strong>Plaats</strong><span className="plaat">:</span><span>Amsterdam</span><br/>
                                { orderList?.thkNumber != 0  ?
                                <>
                                    <strong>Thuiskopie Number</strong><span className="thk-num">:</span><span>{ orderList?.thkNumber }</span>
                                </>
                                :""}
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-inv4">
                        <div class="table-responsive">
                            <table class="table invoice-table">
                                <thead class="bg-active" style={{ backgroundColor: "burlywood"}}>
                                <tr>
                                    <th>Item Nummer</th>
                                    <th>Naam</th>
                                    <th class="text-center">Kwantiteit</th>
                                    <th class="text-center">Prijs({ process.env.REACT_APP_CURRENCY })</th>
                                    <th class="text-right">Totaal({ process.env.REACT_APP_CURRENCY })</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orderItems?.length>0 ? orderItems?.map((item, index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{item?.sku}</td>
                                            <td>
                                                <div class="item-desc-1">
                                                    <span>{item?.productName}</span>
                                                </div>
                                            </td>
                                            <td class="text-center">{item?.itemQty} </td>
                                            <td class="text-center">{(Number(item?.itemPrice?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                            <td class="text-right">{(Number(item?.totalAmount?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                        </tr>
                          
                                )}):""}

                            {thkItems?.length>0? thkItems?.map((thkItem, tindex)=>{
                                return(
                                    <tr key={tindex}>
                                        <td>{thkItem?.thkCode}</td>
                                        <td>
                                            <div class="item-desc-1">
                                                <span>{thkItem?.thkName}</span>
                                            </div>
                                        </td>
                                        <td class="text-center">{thkItem?.thkQty} </td>
                                        <td class="text-center">{(Number(thkItem?.thkPrice?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                        <td class="text-right">{(Number(thkItem?.totalAmount?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    </tr>
                          
                                )}):""}

                                <tr>
                                    <td colspan="4" class="text-end">SubTotaal( { process.env.REACT_APP_CURRENCY } )</td>
                                    <td class="text-right">{ (Number(orderList?.orderSubtotal?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</td>
                                </tr>
                                { orderList?.discount ? 
                                <>
                                
                                <tr>
                                { orderList?.discountType == 'fixed'? 
                                    <td colspan="4" class="text-end">Korting ( {process.env.REACT_APP_CURRENCY} )</td>
                                    :
                                    <td colspan="4" class="text-end">Korting ( { orderList?.discount?.['$numberDecimal'].toLocaleString() }% )</td>
                                }
                                    <td class="text-right">{ (Number(orderList?.orderDiscount?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                                </>
                                :''}
                                <tr>
                                    <td colspan="4" class="text-end">Belasting ( { orderList?.taxPercent?.['$numberDecimal'].toLocaleString() }% )</td>
                                    <td class="text-right">{ (Number(orderList?.tax?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-end fw-bold">Grand Totaal ({ process.env.REACT_APP_CURRENCY  })</td>
                                    <td class="text-right fw-bold">{ (Number(orderList?.orderTotalAmount?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="invoice-informeshon">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="nates mb-30">
                                    <h4 class="inv-title-1">Notitie</h4>
                                    <p class="text-muted">
                                        Wij verzoeken u deze factuur voor {moment(orderList?.createdAt).add(10, "days").format('LL')} te voldoen op bankrekening NL10INGB0008299160 ten name van
                                        Unity Trading B.V. te Amsterdam, onder vermelding van het factuurnummer en uw debiteurennummer (23720135/55532).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="invoice-contact clearfix">
                        <div class="row">
                            <div class="col-lg-9 col-md-9 col-sm-9">
                                <div class="contact-info">
                                    <a><i class="fa fa-map-marker"></i> Unity Trading B.V., Abberdaan 210, 1046 Amsterdam Nederland</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3">
                                <div class="contact-info">
                                    <a><i class="fa fa-envelope"></i> finance@unitytrading.nl</a>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div class="row">
                            <div class="col-lg-8 col-md-8 col-sm-12">
                                <div class="contact-info">
                                    <a><i class="fa fa-globe"></i> www.techbyte.nl</a>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="contact-info">
                                    <span>IBAN nr</span> <span style={{marginLeft:"30px"}}>:</span> NL10INGB0008299160
                                    <br/>
                                    <span> Btw-nummer</span><span style={{marginLeft:"5px"}}>:</span> NL861636168B01
                                    <br/>
                                    <span>KvK</span><span style={{marginLeft:"59px"}}>:</span> 80337422
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</>
)
}