import React, { useState, useEffect } from 'react'
import { Link, useParams,Navigate } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { POST } from '../../helper/service';
import Loader from '../common/Loader';
import PageNotFound from '../Pages/NotFoundPage';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { addToCartAction, getUserCart } from '../../action/cartActions';
import { v4 as uuidv4 } from 'uuid';
import dummyImage from "../../assets/images/placeholder-img-600x600.png";
import placeholder600by600 from "../../assets/images/placeholder-img-600x600.png"
import moment from 'moment/moment';
import axiosIntance from '../../helper/axios';
import { ContactsOutlined } from '@material-ui/icons';
import { responsiveFontSizes } from '@material-ui/core';

export default function Productdetails() {
  let salePrice = 0.00;
  const parser = new DOMParser();
  let applicableDiscount = JSON.parse(localStorage.getItem("userType"))?.applicableDiscount;
  const userLogin = JSON.parse(localStorage.getItem('user'));
  const user = localStorage.getItem("user");
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [newProduct, setNewProduct] = useState([]);
  const params = useParams();
  const [prodId, setProdId] = useState("");
  const [prodQty, setProdQty] = useState(1);
  const updatedCart = useSelector(state => state?.addedToCart);
  const userId = localStorage.getItem("userId");
  const [maxnumber, setmaxnumber] = useState(100);
  const [userOrderList, setUserOrderList] = useState([]);
  const [carouselStartPosition, setCarouselStartPosition] = useState(0);
  const [prodReviews, setProdReviews] = useState();
  const [reviewRating, setReviewRating] = useState();
  const [reviewText, setReviewText] = useState();
  const [itemsToShow, setItemsToShow] = useState(10);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');
  const [noPageStatus, setNoPageStatus] = useState(false);

  let length = "";
  let width = "";
  let height = "";   
      
  const showmore = () => {
      setItemsToShow(newProduct?.specifications.length)
  }

  const showless = () => {
      setItemsToShow(10)
  }

  useEffect(() => {
    fetchProductsDetails();
  }, [params]);

  useEffect(() => {
    dispatch(getUserCart());
  }, [updatedCart]);

  const fetchProductsDetails = (e) => {
    setLoader(true);
    const url = process.env.REACT_APP_BASE_URL + 'get-product-details';
    const data = {
      "slug": params.id
    }
    POST(url, data).then(async response => {
      if(response.success==1){
        if(response.body.length > 0){
          setLoader(false);   
          setNewProduct(response.body[0]);
          setProdId(response?.body[0]?._id);
          const reviewList = await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}get-product-reviews`, {
            itemId: response?.body[0]?._id
          });
          setProdReviews(reviewList);
        }else{
          setLoader(false);   
          setNoPageStatus(true)
        }
      
    }
    })
  }

  const fetchUserOrder = async () => {
    const { data } = await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}get-orders`, {
      //using static user id -> login is not done yet
      userId,
    });
    setUserOrderList(data);
  }

  const onQuantityIncrement = () => {
    if (prodQty < maxnumber) {
      setProdQty(prodQty + 1);
    }
  };

  const onQuantityDecrement = () => {
    if (prodQty <= 1) return;
    setProdQty(prodQty - 1);
  };

  const addToWishlistHandler = async () => {
    const { data } = await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}add-to-wishlist`, {
      //using static user id -> login is not done yet
      userId,
      itemId: prodId
    });
  }

  const addToCartHandler = async (totalPrice, shippingDetails, item, totalQuantity) => {
    if(user){

      const formData ={
        userId : userId,
        item:item
      };
      setLoading(item?.itemId);
      await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}add-to-cart`, {formData}).then(res=>{
        localStorage.removeItem("totalCartQty");
        if(res.data.success==1){
          setLoading('')
          totalQuantity = res.data.body.totalCartQty;
          localStorage.setItem("totalCartQty",res.data.body.totalCartQty);
          dispatch(addToCartAction({ totalPrice, shippingDetails, item, totalQuantity }));
        }else{
          setLoading('')
        }
      }) .catch(error=>{
        console.log(error)
      })

    }else{
      window.location.href='/login';
    }
  }

  const postReview = async (e) => {
    e.preventDefault();
    setLoader(true);
    if(reviewRating==undefined || reviewRating == null || reviewRating==''){
      setError('Please  provide a rating');
      setLoader(false);
      return ;
    }
    if(reviewText==undefined || reviewText == null || reviewText==''){
      setError( 'Please write your review');
      setLoader(false);
      return ;
    }
    else{

        const data = await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}add-review`, {
          userId,
          itemId: prodId,
          rating: reviewRating,
          message: reviewText
      }).then(response=>{
        setLoader(false);
        window.location.reload();
      })
    }

  }

  return (
    <>
    { noPageStatus == true ? <PageNotFound /> : 
      <>
        <Loader loading={loader} />
        <main className="main">
          <div className="container">
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={"/"}>
                <i className="icon-home" />
                </Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">Winkel</li>
                <li className="breadcrumb-item" aria-current="page">{newProduct?.name}</li>
              </ol>
            </nav>
            <div className="product-single-container product-single-default">
              <div className="cart-message d-none">
                <strong className="single-cart-notice">{newProduct?.name}</strong>
                <span>is toegevoegd aan uw winkelwagen.</span>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-6 product-single-gallery">
                  <div className="product-slider-container">
                    <div className="label-group"> 
                    </div>

                    <OwlCarousel className='owl-theme' loop margin={10} items={1} nav dotData={true} dots={false} startPosition={carouselStartPosition} >
                      {newProduct.images?.map((img) => {
                        return (

                          <div >
                            <img src={img} width={360} height={280} alt="category banner" />

                          </div>
                        )
                      })
                      }
                    </OwlCarousel> 
                  </div>
                  <div className="prod-thumbnail owl-dots">
                    {
                      newProduct?.images?.map((img, i) => {
                        return (
                          <div className="owl-dot" onClick={() => {
                            setCarouselStartPosition(i);
                          }} >
                            <img src={img} width="110" height="110"
                              alt="product-thumbnail" />
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 product-single-details">
                  <h1 className="product-title">{newProduct.name}</h1>             
                  <hr className="short-divider" />
                  <div className="price-box">
                    <del className="old-price"></del>
                    <span className="product-price"></span>
                  </div>
                  <div className="product-desc">
                    <p>
                      {newProduct.shortDescription}
                    </p>
                  </div>
                  <ul className="single-info-list">

                  {(newProduct?.salePrice ? <> 
                    <li>
                      <div className="price-box">
                        {applicableDiscount ?
                          <>
                          <span className="product-price">{user ? (process.env.REACT_APP_CURRENCY + (Number(parseFloat(newProduct?.salePrice['$numberDecimal']) - ( (applicableDiscount/100) * parseFloat(newProduct?.salePrice['$numberDecimal'] ))).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")) : ""}</span>
                          </>
                          :
                          <>
                          <span className="product-price">{user ? (process.env.REACT_APP_CURRENCY + (Number(parseFloat(newProduct?.salePrice['$numberDecimal'])).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")) : ""}</span>
                          </>
                        }
                      </div>
                    </li>
                    </> : <></>)}

                    <li>
                      Stock:
                      &nbsp;&nbsp;<strong>{newProduct.stockQty}</strong>
                    </li>
                    <li>
                      SKU:
                      &nbsp;&nbsp;<strong>{newProduct.sku}</strong>
                    </li>
                    <li>
                      Categorieen:
                      &nbsp;&nbsp;<strong>
                        {newProduct.category?.map((newcategory) => {
                          return (
                            <span>{newcategory.name}</span>
                          )
                        })
                        }
                      </strong>
                    </li> 
                  </ul>

                  <div className="product-action"> 
                    {/* <div className='product-single-qty'>
                      <button disabled={(newProduct.stockQty ? false : true)} className="px-5 py-1 font-bold bg-gray-200 text-black prod-qty" onClick={(e) => onQuantityDecrement()}>-</button>
                      <input value={prodQty} className="w-20 bg-sky-50 text-center py-1 mob-inp" onChange={(e) => setProdQty(e.target.value)} />
                      <button disabled={(newProduct.stockQty ? false : true)}  className="px-5 py-1 font-bold bg-gray-200 text-black prod-qty" onClick={(e) => onQuantityIncrement()}>+</button>
                    </div> */}
                    {(newProduct.stockQty ? <>
                      <a href=""
                      onClick={(e) => {
                        if(applicableDiscount){
                          salePrice = parseFloat(newProduct?.salePrice['$numberDecimal']) - ( (applicableDiscount/100) * parseFloat(newProduct?.salePrice['$numberDecimal']))
                        }else{
                          salePrice = parseFloat(newProduct?.salePrice['$numberDecimal'])
                        }
                        let totalPrice = (Number(salePrice) * Number(prodQty)).toString();
                        let shippingDetails = {};
                        let totalQuantity = prodQty; 
                        let item = {
                          id: uuidv4(),
                          itemId:   prodId,
                          thkId:    newProduct?.thuiskopieId,
                          product:  newProduct?.name,
                          quantity: prodQty,
                          price:    salePrice,
                          subTotal: totalPrice,
                          size: "",
                          imgPath:  newProduct?.images?.[0] ? newProduct?.images?.[0] : placeholder600by600,
                          slug:     newProduct?.slug,
                          availableStock:newProduct.stockQty
                        }
                        addToCartHandler(totalPrice, shippingDetails, item, totalQuantity)
                      }}
                      className="btn btn-dark add-cart mr-2">{loading == prodId ? 'Even geduld aub' : 'Toevoegen'}</a>
                    </> : <>
                    <a href="" 
                      className="btn btn-dark  mr-2 disabled">Uitverkocht</a>
                    </>)}
                  <a href="/cart" className="btn btn-gray view-cart d-none">Zie Winkelwagen</a>
                  </div>
                  <hr className="divider mb-0 mt-0" />
                  <div className="product-single-share mb-3">
                    {
                      userLogin ? (
                        <a href=""
                      onClick={() => addToWishlistHandler()}
                      className="btn-icon-wish add-wishlist">
                      <i className="icon-heart" />
                      <span>Zet in verlanglijst
                      </span>
                    </a>
                      ) : null
                    }
                  </div>
                </div>
              </div> 
            </div>
            

            <div className="product-single-tabs">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="product-tab-desc" data-toggle="tab" href="#product-desc-content" role="tab" aria-controls="product-desc-content" aria-selected="true">Beschrijving</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="product-tab-tags" data-toggle="tab" href="#product-tags-content" role="tab" aria-controls="product-tags-content" aria-selected="false">Specificatie
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="product-tab-reviews" data-toggle="tab" href="#product-reviews-content" role="tab" aria-controls="product-reviews-content" aria-selected="false">Beoordelingen ({prodReviews?.data?.body?.totalReviews})</a>
                </li>
              </ul>
              
              <div className="tab-content">
                <div className="tab-pane fade show active" id="product-desc-content" role="tabpanel" aria-labelledby="product-tab-desc">
                  <div className="product-desc-content"> 
                    {(newProduct.longDescripition ? <div dangerouslySetInnerHTML={{__html: parser.parseFromString( newProduct.longDescripition, 'text/html').body.textContent}} /> : <p>Niets Gevonden</p>)}
                  </div> 
                </div>
                <div className="tab-pane fade" id="product-tags-content" role="tabpanel" aria-labelledby="product-tab-tags">
                  <table className="table table-striped mt-2">
                    <tbody>  
                      { 
                        newProduct ? newProduct?.specifications?.slice(0, itemsToShow)?.map((data, i) => {    
                          if(data.attributeName == "Length") length = data.attributeValue;            
                          if(data.attributeName == "Width") width = data.attributeValue;
                          if(data.attributeName == "Height") height = data.attributeValue;
                          return(
                            <>
                              <tr>
                                    <th>{data?.attributeName}</th>
                                    <td>{data?.attributeValue}</td>
                              </tr> 
                            </>
                          )
                        }) : <p>Niets Gevonden</p>
                      }
                      {newProduct?.specifications?.length > 5 ?
                      <>
                        {(itemsToShow === 10) ? <button className='btn-dark show-sepic' onClick={showmore}>Show More</button>: <button className='btn-dark show-sepic' onClick={showless}>Show Less</button>}
                      </>
                      :""}
                      </tbody>
                  </table>
                </div> 

                <div className="tab-pane fade" id="product-reviews-content" role="tabpanel" aria-labelledby="product-tab-reviews">
                  <div className="product-reviews-content">
                    <h3 className="reviews-title">{prodReviews?.data?.body?.totalReviews} review for {newProduct.name}</h3>
                    <div className="comment-list">
                      {
                        prodReviews ? prodReviews?.data?.body?.reviews?.map((list, i) => {
                          return(
                            <div className="comments">
                              <figure className="img-thumbnail">
                                <img src={dummyImage} alt="author" width={80} height={80} />
                              </figure>
                              <div className="comment-block">
                                <div className="comment-header">
                                  <div className="comment-arrow" />
                                  <div className="ratings-container float-sm-right">
                                    <div className="product-ratings">
                                      <span className="ratings" style={{ width: ((list.rating/5) * 100) }} />
                                        <span className="tooltiptext tooltip-top" />
                                    </div>
                                    
                                  </div>
                                  <span className="comment-by">
                                    <strong>{list.users.personName}</strong> – {moment(list?.createdAt).format('LL')}
                                  </span>
                                </div>
                                <div className="comment-content">
                                  <p>{list.message}</p>
                                </div>
                              </div>
                            </div>
                          )
                        }): null
                      }
                    </div>

                    {userLogin ?
                      <>
                        <div className="divider" />
                        <div className="add-product-review">
                          <h3 className="review-title">Voeg een beoordeling toe</h3>
                          <form action="#" className="comment-form m-0" onSubmit={postReview} >
                            <div className="rating-form">
                              <label htmlFor="rating">Jouw beoordeling
                                <span className="required">*</span>
                              </label>
                              <span className="rating-stars">
                                <a className="star-1" onClick={() => setReviewRating(1)}>1</a>
                                <a className="star-2" onClick={() => setReviewRating(2)}>2</a>
                                <a className="star-3" onClick={() => setReviewRating(3)}>3</a>
                                <a className="star-4" onClick={() => setReviewRating(4)}>4</a>
                                <a className="star-5" onClick={() => setReviewRating(5)}>5</a>
                              </span>
                              <select name="rating" id="rating" required style={{ display: 'none' }}>
                                <option value>Rate…</option>
                                <option value={5}>Perfect</option>
                                <option value={4}>Good</option>
                                <option value={3}>Average</option>
                                <option value={2}>Not that bad</option>
                                <option value={1}>Very poor</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Jouw beoordelingen
                                <span className="required">*</span>
                              </label>
                              <textarea cols={5} rows={6} className="form-control form-control-sm" defaultValue={""}
                              value={reviewText} onChange={(e) => setReviewText(e.target.value)}
                              required
                              />
                            </div> 
                            <input type="submit" className="btn btn-primary" value="OPSLAAN" />
                            <br/>
                            <span style={{color:"red"}}>{error !='' ? error :''}</span>
                          </form>
                        </div>
                      </>
                      : null} 
                  </div> 
                </div> 
              </div> 

            </div> 
          </div>
        </main>
      </>
    }
    </>
  )
}
