import React, { useState, useEffect } from 'react' 
import { useParams } from "react-router-dom"; 
import { Container, Alert } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from 'moment/moment';
import axiosIntance from '../../helper/axios';

export default function OrderDetails(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  const [orderlist, setOrderList] = useState([]);
  const [orderitems, setOrderItems] = useState([]);
  const [thkItems, setTHKItems] = useState([]);
  const [ordertotal, setOrderTotal] = useState([]);
  const [ordertotaltax, setOrderTotalTax] = useState([]);
  const [orderitemid, setOrderItemID] = useState([]);
  const [userId, setUserID] = useState(user?._id);
 
  const params = useParams();
  const queryParameters = params.id;
  const [orderId, setOrderId] = useState(queryParameters);
  const [loader, setLoader] = useState(false);
  const [AlertText, setAlertText] = useState();
  const [Variant, setVariant] = useState("");
 
  
  const fetchcorderData = (e) => {
    setLoader(true);
    const url = process.env.REACT_APP_BASE_URL + 'order-details';
    const data = {
      "orderId": params.id
    }
    axiosIntance.post(url, data).then(response => { 
      console.log('ORes',response)
      setOrderList(response?.data?.body[0]);
      setOrderItems(response?.data?.body[0].orderItems);
      setTHKItems(response?.data?.body[0].thkItems)
      setOrderTotal(response?.data?.body[0].orderTotalAmount.$numberDecimal);
      setOrderTotalTax(response?.data?.body[0].tax.$numberDecimal); 
    }).catch((error)=>{
      console.log("There is something wrong")
    })

  }
  
  
  useEffect(() => {
    fetchcorderData();
  }, []);

return (
    <>
      <main className="main">
        <div className="card fix-ordercard" style={{maxWidth:"95%"}}>
          <div className="App col-md-12">
            <Alert variant={Variant} show={AlertText ? true : false}>
              {AlertText}
            </Alert>
          </div>


          <div className="info fix-info">
            <div className="title fix-ordertitle">Bestel Details</div>
            <div className="row">
              <div className="col-4 bg-left">
                <span id="heading">Afleveradres:</span><br />
                <span id="details">{orderlist?.shippingAddress}</span><br /> 
                <span id="details">{orderlist?.shippingCity}</span><br />
                <span id="details">{orderlist?.shippingState}</span><br />
                <span id="details">{orderlist?.shippingZipcode}</span><br />
                <span id="details">{orderlist?.shippingCountryName}</span><br />
              </div>

              <div className='col-4 bg-left'>
                <span id="heading">Factuuradres:</span><br />
                <span id="details">{orderlist?.address}</span><br /> 
                <span id="details">{orderlist?.city}</span><br />
                <span id="details">{orderlist?.state}</span><br />
                <span id="details">{orderlist?.zipcode}</span><br />
                <span id="details">{orderlist?.countryName}</span><br />
              </div>

              <div className="col-4 py-2"> 
                <a className="btn view-btun" href={`/invoice-details/${params.id}`} target="_blank">
                  Bekijk Factuur
                </a><br />
                <span id="heading">Bestel Datum:</span><br />
                <span id="details">{moment(orderlist?.createdAt).format('MMMM DD , YYYY')}</span><br />
                <span id="heading">Bestelnummer:</span><br />
                <span id="details">{orderlist?.orderId}</span><br/>
                <span id="heading">Bestelstatus:</span><br />
                <span id="details">
                  {(()=>{
                      if(orderlist?.orderStatus == 1){
                        return ( 'Besteld' )
                      }
                      if(orderlist?.orderStatus == 7){
                        return ( 'In de wacht' )
                      }else{
                        return ( 'Geannuleerd' )
                      }
                  })
                  ()}
                </span><br/>
                <span id="heading">Betaalmethode:</span><br />
                <span id="details">
                  {(()=>{
                      if(orderlist?.paymentMode == 1 ){
                        return ( 'iDeal' )
                      }else{
                        return ( 'Bankoverschrijving' )
                      }
                  })
                  ()}
                </span>
                <br/>
                { orderlist?.thkNumber !=0 ?
                <>
                  <span id="heading">Thuiskopie Number:</span><br />
                  <span id="details">
                    {orderlist?.thkNumber !=0 ? orderlist?.thkNumber : ''}
                  </span>
                </>
                :''}
              </div>

            </div>
            <hr className='hr-order' style={{margin:"15px 0px"}}/>
            <div  className='row'>
              <div className='col-12 bg-left mar-order'>
                <span id="name">Subtotaal: </span>
                <span id="price">{process.env.REACT_APP_CURRENCY} {(Number(orderlist?.orderSubtotal?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span><br />
                <span id="name">Levering Kosten: </span>
                <span id="price">{process.env.REACT_APP_CURRENCY} 0</span><br />
                { orderlist?.discountType == 'fixed' ? 
                  <span id="name">Korting: </span>
                :
                  <span id="name">Korting ( { orderlist?.discount?.['$numberDecimal']}% ) : </span>
                }
                <span id="price">{process.env.REACT_APP_CURRENCY} {(Number(orderlist?.orderDiscount?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span><br />
                {orderlist?.coupon ? 
                <>
                <span id="name">Coupon: </span>
                <span id="price">{orderlist?.coupon}</span>
                </>
                :''}
                <br/>
                <span id="name">Belasting ( { orderlist?.taxPercent?.['$numberDecimal']}% ): </span>
                <span id="price">{process.env.REACT_APP_CURRENCY} { ( Number( orderlist?.tax?.['$numberDecimal']).toFixed(2) ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</span><br />
                <span id="name">Order Totaal: </span>
                <span id="price">{process.env.REACT_APP_CURRENCY} {(Number(orderlist?.orderTotalAmount?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span><br />
              </div>
            </div>
          </div>
          <div className="pricing">

            { orderitems ? orderitems.map((item, i) => (

              <div className="row">
                <div className="col-6 bg-left">                  
                  <span id="name" style={{fontWeight:"bold"}}>{item.productName}</span><br /><br/>
                  <span id="name">Item Prijs: </span>
                  <span id="price">{process.env.REACT_APP_CURRENCY} {(Number(item?.itemPrice?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span><br />
                  <span id="name">Kwantiteit: </span>
                  <span id="price">{ item?.itemQty }</span><br />
                  <span id="name">Item Totaal: </span>
                  <span id="price">{process.env.REACT_APP_CURRENCY} {(Number(item?.totalAmount?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span><br />                                 
                </div>
                <div className="col-6 bg-left">
                  <div className='title'>
                    Item Totaal:
                    <big> {process.env.REACT_APP_CURRENCY} { (Number(item?.totalAmount?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</big>
                  </div>
                  { ( ()=>{
                    if(orderlist?.orderStatus != 5){
                      return(
                        <>
                        {/* <div className="tracking">
                          <div className="title">Tracking Order</div>
                        </div>
                        <div className="progress-track">
                          <ul id="progressbar">
                            <li className="step0 active " id="step1">On-hold</li>
                            <li className="step0 active " id="step1">Ordered</li>
                            <li className="step0 active text-center" id="step2">Shipped</li>
                            <li className="step0 active text-right" id="step3">On the way</li>
                            <li className="step0 text-right" id="step4">Delivered</li>
                          </ul>
                        </div> */}
                        </>
                      )
                    }
                  })
                ()}
                </div>
                {/* <div className="col-3">
                  <a href="#" className="btn-list btn-fixlist" onClick={() => { if (window.confirm('Are you sure to cancel this Order?')) { handlecancelorder(item.itemId) }; }}>Cancel</a>
                  <a href="#" className="btn-list btn-fixlist" onClick={() => { if (window.confirm('Are you sure to get refund for  this Order?')) { handlerefundorder(item.itemId) }; }}>Refund</a>
                  <a href="#" className="btn-list btn-fixlist" >Track</a>
                </div> */}
              </div>
            ))
            :''
            }

            { thkItems ? thkItems.map((thkItem, i) => (

            <div className="row">
              <div className="col-6 bg-left">                  
                <span id="name" style={{fontWeight:"bold"}}>{( thkItem.thkCode)} {thkItem.thkName}</span><br /><br/>
                <span id="name">Item Prijs: </span>
                <span id="price">{process.env.REACT_APP_CURRENCY} {(Number(thkItem?.thkPrice?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span><br />
                <span id="name">Kwantiteit: </span>
                <span id="price">{ thkItem?.thkQty }</span><br />
                <span id="name">Item Totaal: </span>
                <span id="price">{process.env.REACT_APP_CURRENCY} {(Number(thkItem?.totalAmount?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span><br />                                 
              </div>
              <div className="col-6 bg-left">
                <div className='title'>
                  Item Totaal:
                  <big> {process.env.REACT_APP_CURRENCY} { (Number(thkItem?.totalAmount?.['$numberDecimal']).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</big>
                </div>
              </div>
              {/* <div className="col-3">
                <a href="#" className="btn-list btn-fixlist" onClick={() => { if (window.confirm('Are you sure to cancel this Order?')) { handlecancelorder(item.itemId) }; }}>Cancel</a>
                <a href="#" className="btn-list btn-fixlist" onClick={() => { if (window.confirm('Are you sure to get refund for  this Order?')) { handlerefundorder(item.itemId) }; }}>Refund</a>
                <a href="#" className="btn-list btn-fixlist" >Track</a>
              </div> */}
            </div>
            ))
            :''
            }

          </div>
          <div className="footer">
          </div>
        </div>
      </main>
    </>
  )
}
