export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_SUCCESS";

export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_FAIL = "REMOVE_FROM_CART_FAIL";

export const GET_USER_CART_REQUEST = "GET_USER_CART_REQUEST";
export const GET_USER_CART_SUCCESS = "GET_USER_CART_SUCCESS";
export const GET_USER_CART_FAIL = "GET_USER_CART_FAIL";

export const EMPTY_CART_REQUEST = "EMPTY_CART_REQUEST";
export const EMPTY_CART_SUCCESS = "EMPTY_CART_SUCCESS";
export const EMPTY_CART_FAIL = "EMPTY_CART_FAIL";