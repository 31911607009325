import React from 'react';
import Config from "../../config.json"
import { useParams } from "react-router-dom";

export default function ErrorPage() {
    const params = useParams();

    return (
        <>
            <div className="container ">
                <div className="row ordersuccess ">
                    <div className="col-10 mx-auto card-ordersuccess">
                        <img src={`${Config.REACT_BASE_URL}/custom/assets/images/error.png`} alt=''/>
                        {params.pathParam == 'pay-408' ?
                        <>
                            <p className="error-explanation text-center" style={{fontWeight:"bold",fontSize:"18px"}}>
                                iDeal-betaling is uitgeschakeld
                            </p>
                        </>
                        :
                            <p className="error-explanation text-center" style={{fontWeight:"bold",fontSize:"18px"}}>
                                Het spijt ons. Er is iets fout gegaan.
                            </p>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}