import React from 'react';
import { usePagination, DOTS } from '../../helper/cutomHooks/usePagination';

const Pagination = props => {
  const {
    setPage,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  let lastPage = paginationRange[paginationRange.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  
  const onNext = () => {
    if(currentPage!=lastPage){
        setPage(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if(currentPage!=1){
        setPage(currentPage - 1);
    }
  };

  
  return (
    <ul className="pagination mb-0 pagination-shop justify-content-center justify-content-md-start">
      <li className={"fa fa-angle-left right-1 slick-arrow u-slick__arrow-normal "+(currentPage == 1 ? 'slick-disabled': '')} onClick={onPrevious}
      style={{cursor:"pointer"}} >
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (

            <li className="page-item" key={pageNumber}>
            <a className={`page-link ${currentPage == pageNumber ? 'current' : ''} `} style={{cursor:"pointer"}}
                onClick={() => setPage(pageNumber)} >{pageNumber}</a>
            </li>

        );
      })}
      <li
        className={"fa fa-angle-right right-1 slick-arrow u-slick__arrow-normal "+(currentPage == lastPage ? 'slick-disabled': '')} onClick={onNext}
        style={{cursor:"pointer"}}>
      </li>
    </ul>
  );
};

export default Pagination;
