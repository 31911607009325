import { ContactsOutlined } from "@material-ui/icons";
import { cartState } from "../initState/cartState";
import {
    ADD_TO_CART_REQUEST,
    ADD_TO_CART_SUCCESS,
    ADD_TO_CART_FAIL,
    GET_USER_CART_REQUEST,
    GET_USER_CART_SUCCESS,
    GET_USER_CART_FAIL,
    REMOVE_FROM_CART_REQUEST,
    REMOVE_FROM_CART_SUCCESS,
    REMOVE_FROM_CART_FAIL,
    EMPTY_CART_REQUEST,
    EMPTY_CART_SUCCESS,
    EMPTY_CART_FAIL
} from "../types/cartTypes";


export const addToCartReducer = (state = cartState, action) => { 

    switch (action.type) {
        case ADD_TO_CART_REQUEST:
            let formattedCart = {};
            let cartCurrentState = localStorage.getItem("userCart");
            if (cartCurrentState == null) {
                formattedCart = {
                    items: [],
                    totalPrice: 0,
                    totalQuantity: 0,
                    shippingDetails: {}
                }
                return { loading: true, cart: formattedCart };
            }
            formattedCart = JSON.parse(cartCurrentState);
            return { loading: true, cart: formattedCart };

        case ADD_TO_CART_SUCCESS:

            let updatedItems;
            let existingItemIndex = state?.cart?.items?.findIndex(item => item?.itemId === action?.payload?.item?.itemId);
            let existingItem = state?.cart?.items[existingItemIndex];

            if(action?.payload?.type == undefined){ // if item added from wishlist list
                var stockVal = (existingItem?.quantity + action?.payload?.item?.quantity)
            }else{
                var stockVal = (action?.payload?.type == "plus" ? existingItem?.quantity + action?.payload?.item?.quantity : (existingItem?.quantity - action?.payload?.item?.quantity > 0 ? existingItem?.quantity - action?.payload?.item?.quantity : 1))
            }

            // if(existingItem?.availableStock < stockVal){ 
            //     //    alert("Nog maar "+existingItem?.availableStock+" product op voorraad.");
            //        return false;
            // }
            
            if (existingItem) {
                let updatedItem = {
                    ...existingItem,
                    quantity: stockVal
                }
                updatedItems = [...state.cart.items];
                updatedItems[existingItemIndex] = updatedItem
            } else {
                updatedItems = state.cart.items.concat(action.payload.item)
            }

            const cartInfoData = updatedItems[0]; 
            if(isNaN(state.cart.totalPrice)){
                state.cart.totalPrice  = 0;
            }   

            let updatedTotalPrice = ((parseFloat((state.cart.totalPrice)) + parseFloat(parseFloat(cartInfoData.price * cartInfoData.quantity))).toFixed(2)).toString();
            // let updatedTotalQuantity = ((parseInt(state.cart.totalQuantity) + parseInt(action.payload.totalQuantity))).toString();
            let updatedTotalQuantity = action.payload.totalQuantity;
            console.log('action.payload.totalQuantity',action.payload.totalQuantity)
            updatedTotalPrice = parseFloat(cartInfoData.price) * parseFloat(cartInfoData.quantity);
            
            localStorage.setItem("userCart", JSON.stringify({
                items: updatedItems,
                totalPrice: updatedTotalPrice,
                totalQuantity: updatedTotalQuantity,
                shippingDetails: action.payload.shippingDetails
            }))
            return {
                loading: false, cart: {
                    items: updatedItems,
                    totalPrice: updatedTotalPrice,
                    totalQuantity: updatedTotalQuantity,
                    shippingDetails: action.payload.shippingDetails
                }
            };

        case ADD_TO_CART_FAIL:
            return { loading: false, cart: null, error: action.payload };
    }
    return state;
}

export const getUserCartReducer = (state = cartState, action) => {
    switch (action.type) {
        case GET_USER_CART_REQUEST:
            return { loading: true, cart: null };

        case GET_USER_CART_SUCCESS:
            let formattedCart = {};
            let cartCurrentState = localStorage.getItem("userCart"); 
            if (cartCurrentState == null) {
                formattedCart = {
                    items: [],
                    totalPrice: 0,
                    totalQuantity: 0,
                    shippingDetails: {}
                }
                localStorage.setItem("userCart", JSON.stringify({ ...formattedCart }))
                return { loading: true, cart: formattedCart };
            }
            formattedCart = JSON.parse(cartCurrentState);
            return {
                loading: false, cart: { ...formattedCart }
            };

        case GET_USER_CART_FAIL:
            return { loading: false, cart: null, error: action.payload };
    }
    return state;
}

export const removeItemFromCartReducer = (state = cartState, action) => {
    switch (action.type) {
        case REMOVE_FROM_CART_REQUEST:
            let formattedCart = {};
            let cartCurrentState = localStorage.getItem("userCart");
            if (cartCurrentState == null) {
                formattedCart = {
                    items: [],
                    totalPrice: 0,
                    totalQuantity: 0,
                    shippingDetails: {}
                }
                return { loading: true, cart: formattedCart };
            }
            formattedCart = JSON.parse(cartCurrentState);
            return { loading: true, cart: formattedCart };

        case REMOVE_FROM_CART_SUCCESS:
            const getItem = state.cart.items.find(data => data.itemId === action.payload.id);
            let updatedTotalPrice = ((Number(state.cart.totalPrice) - Number(getItem.subTotal)).toFixed(2)).toString();
            let updatedTotalQuantity = ((Number(state.cart.totalQuantity) - Number(getItem.quantity))).toString();
            let updatedItems = state.cart.items.filter(data => data.itemId !== action.payload.id);
            const updatedCart = {
                items: updatedItems,
                totalPrice: updatedTotalPrice,
                totalQuantity: updatedTotalQuantity,
                shippingDetails: state.cart.shippingDetails
            }
            localStorage.setItem("userCart", JSON.stringify({ ...updatedCart }))

            return {
                loading: false, cart: { ...updatedCart }
            };

        case REMOVE_FROM_CART_FAIL:
            return { loading: false, cart: null, error: action.payload };
    }
    return state;
}

export const emptyCartReducer = (state = cartState, action) => {
    switch (action.type) {
        case EMPTY_CART_REQUEST:
            let formattedCart = {};
            let cartCurrentState = localStorage.getItem("userCart");
            if (cartCurrentState == null) {
                formattedCart = {
                    items: [],
                    totalPrice: 0,
                    totalQuantity: 0,
                    shippingDetails: {}
                }
                return { loading: true, cart: formattedCart };
            }

            formattedCart = JSON.parse(cartCurrentState);
            return { loading: true, cart: formattedCart };

        case EMPTY_CART_SUCCESS:
            const updatedCart = {
                items: [],
                totalPrice: 0,
                totalQuantity: 0,
                shippingDetails: {}
            }``
            localStorage.removeItem("userCart");

            return {
                loading: false, cart: { ...updatedCart }
            };

        case EMPTY_CART_FAIL:
            return { loading: false, cart: null, error: action.payload };
    }
    return state;
}