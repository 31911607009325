import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Alert} from "react-bootstrap";
import axiosIntance from "../../helper/axios";
import Config from '../../config.json'

export default function ResetPassword(props) {
  async function resetpassword(credentials) {
    console.log('credentials',credentials)
    const url = process.env.REACT_APP_BASE_URL + "reset-password";
    return axiosIntance.post(url, credentials).then((data) => data);
  }
  const [newinput, setInput] = useState({
    password: "",
    cpassword: "",
  });
  const params = useParams();
  const queryParameters = params.id;
  const [loading,setLoading] = useState(false);
  const [userId, setUserId] = useState(queryParameters);
  const [AlertText, setAlertText] = useState();
  const [Variant, setVariant] = useState("");
  const [error, setError] = useState({
    password: "",
    cpassword: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      password: newinput.password,
      userId: userId,
    };
    setLoading(true);
    const response = await resetpassword(obj);
    if (response.data.success == 1) {
      setLoading(false)
      setAlertText(response.data.message);
      setVariant("success");
      setTimeout(()=>{window.location.href = '/login'},1000)
    } else {
      setLoading(false)
      setAlertText(response.data.message);
      setVariant("danger");
    }
  };
  const onInputChange = (e) => {
    const {name, value} = e.target;
    console.log(value)
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let {name, value} = e.target;
    setError((prev) => {
      const stateObj = {...prev, [name]: ""};

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Vul uw wachtwoord in.";
          } else if (newinput.cpassword && value !== newinput.cpassword) {
            stateObj["cpassword"] = "Wachtwoord en bevestiging wachtwoord komen niet overeen.";
          } else {
            stateObj["cpassword"] = newinput.cpassword ? "" : error.cpassword;
          }
          break;

        case "cpassword":
          if (!value) {
            stateObj[name] = "Vul uw bevestiging wachtwoord in.";
          } else if (newinput.password && value !== newinput.password) {
            stateObj[name] = "Wachtwoord en bevestiging wachtwoord komen niet overeen.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  return (
    <main className="main">
      <div className="page-header">
        <div className="container d-flex flex-column align-items-center">
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="demo4.html">Home</a>
                </li>
              </ol>
            </div>
          </nav>

          <h1>Wijzig wachtwoord</h1>
        </div>
      </div>

      <div className="container reset-password-container">
        <div className="row">
          <div className="App col-md-12">
            <Alert variant={Variant} show={AlertText ? true : false}>
              {AlertText}
            </Alert>
          </div>
          <div className="col-lg-6 offset-lg-3">
            <div className="feature-box border-top-primary">
              <div className="feature-box-content">
                <form className="mb-0" action="#" onSubmit={handleSubmit}>
                  <div className="form-group mb-0">
                    <label for="reset-email" className="font-weight-normal">
                      Voer een nieuw wachtwoord in
                    </label>
                    <input
                      required
                      fullWidth
                      id="password"
                      name="password"
                      label="Password"
                      type="password"
                      className="form-input form-wide mb-2"
                      value={newinput.password}
                      onChange={onInputChange}
                      onBlur={validateInput}
                    />
                    {error.password && <span className="err">{error.password}</span>}
                  </div>
                  <div className="form-group mb-0">
                    <label for="reset-email" className="font-weight-normal">
                    Bevestiging Wachtwoord
                    </label>
                    <input
                      required
                      fullWidth
                      id="cpassword"
                      name="cpassword"
                      label="cpassword"
                      type="password"
                      className="form-input form-wide mb-2"
                      value={newinput.cpassword}
                      onChange={onInputChange}
                      onBlur={validateInput}
                    />
                    {error.cpassword && <span className="err">{error.cpassword}</span>}
                  </div>

                  <div className="form-footer mb-0">
                    { loading ? 
                    <>
                      ...even geduld. Herstel link wordt gestuurd&nbsp;<img src={Config.REACT_BASE_URL+'/custom/assets/images/loading.gif'} style={{width:'35px',height:'35px'}}/>
                    </>
                    :''}
                    <button type="submit"
                            className="btn btn-md btn-primary form-footer-right font-weight-normal text-transform-none mr-0"
                            disabled={ loading ? 'disabled' : '' }>
                      Wijzig Wachtwoord
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
