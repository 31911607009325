const TermAndCond = () => {
    return (
        <main className="main about">
            <div className="page-header page-header-bg text-center" style={{ background: '50%/cover #D4E1EA url("assets/images/page-header-bg.jpg")' }}>
                <div className="container">
                    <h1>Terms and Conditions</h1>
                </div>{/* End .container */}
            </div>{/* End .page-header */}
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/"><i className="icon-home" /></a></li>
                        <li className="breadcrumb-item active" aria-current="page">T&C</li>
                    </ol>
                </div>{/* End .container */}
            </nav>
            <div className="about-section">
                <div className="container heda-text">
                <h1 className="t_c-heading text-center">General Terms and Conditions Techbyte.nl</h1>
                <h1>Introduction:</h1>
                <ul className="intro-artical">
                    <li>
                    Article 1 – Definitions
                    </li>
                    <li>Article 2 – Identity of the Entrepreneur (Techbyte.nl)</li>
                    <li>Article 3 – Applicability</li>
                    <li>Article 4 – The offer</li>
                    <li>Article 5 – The agreement</li>
                    <li>Article 6 – Reflection period</li>
                    <li>Article 7 – Obligations of the Entrepreneur (Techbyte.nl) for returns</li>
                    <li>Article 8 – The price</li>
                    <li>Article 9 – Compliance with agreement</li>
                    <li>Article 10 – Delivery and execution</li>
                    <li>Article 11 – Payment</li>
                    <li>Article 12 – Applicable law and competent court</li>
                    <li>Article 13 – Additional or deviating provisions</li>
                    <li>Article 14 – Changes to the General Terms and Conditions</li>
                </ul>
                </div>
                <div className="container">

                    <h2 className="subtitle">Article 1 - Definitions</h2>
                    <p>In these terms and conditions:</p>
                    {/* <p>All sales conducted by Unity Trading BV to its customers are governed by these terms and conditions. The purchaser's acknowledgment and acceptance of these terms and conditions shall be established either by<br />
                        (i) providing a purchase order number or,<br />
                        (ii) receiving and accepting any product from Unity Trading BV,<br />
                        whichever comes first. These terms and conditions shall be applicable to the sale of all products that Unity Trading BV's sells, unless explicitly stated otherwise ("Product").</p> */}
                       
                        <ul>
                            <li>
                            Additional agreement: an agreement whereby the Entrepreneur acquires products, digital content in connection with a distance contract and these items are delivered by the Entrepreneur (Techbyte.nl) or by a third party on the basis of an agreement between that third party and the Entrepreneur ( Techbyte.nl);
                            </li>
                            <li>
                            Reflection period: the period within which the Entrepreneur can make use of his right of withdrawal;
                            </li>
                            <li>
                            Entrepreneur: the natural person who does not act for purposes related to his trade, business, craft or professional activity;
                            </li>
                            <li>
                            Day: calendar day;
                            </li>
                            <li>
                            Digital content: data produced and delivered in digital form;
                            </li>
                            <li>
                            Durable data carrier: any tool - including e-mail - that enables the Entrepreneur or Entrepreneur (Techbyte.nl) to store information that is addressed to him personally in a way that prevents future consultation or use during a period that is tailored to the purpose for which the information is intended, and which allows unaltered reproduction of the stored information;
                            </li>
                            <li>
                            Right of withdrawal: the possibility of the Entrepreneur to waive the distance contract within the cooling-off period;
                            </li>
                            <li>
                            Entrepreneur (Techbyte.nl): the natural or legal person who offers products to Entrepreneur at a distance;
                            </li>
                            <li>
                            Distance Agreement: an agreement that is concluded between the Entrepreneur (Techbyte.nl) and the Entrepreneur in the context of an organized system for the distance sale of products whereby, up to and including the conclusion of the agreement, use is made exclusively or in part of one or more remote communication techniques;
                            </li>
                            <li>
                            Model withdrawal form: the European model withdrawal form included in Annex I of these terms and conditions; Appendix I need not be made available if the Entrepreneur has no right of withdrawal with regard to his order;
                            </li>
                            <li>
                            Technique for distance communication: means that can be used to conclude an agreement, without the Entrepreneur and Entrepreneur (Techbyte.nl) having to come together in the same room at the same time.
                            </li>
                        </ul>

                </div>{/* End .container */}
                <div className="container">
                    <h2 className="subtitle">Article 2 - Identity of the Entrepreneur</h2>
                    {/* <p>Purchaser must provide Unity Trading BV with complete Product order information as required by Unity Trading BV. The Product order information will include without limitation the<br />
                        (i) Product description,<br />
                        (ii) Unit quantity,<br />
                        (iii) Manufacturer and/or vendor part number,<br />
                        (iv) Current unit price as provided by Unity Trading, and<br />
                        (v) Correct shipping address.<br />
                        Orders for direct shipment to Purchaser’s customer may require prepayment and may be subject to additional fees.</p> */}
                        <p>Name of Entrepreneur: Unity Trading B.V.</p>
                        <p>Trading under the name(s): - Techbyte.nl</p>
                        <p>Business address: Abberdaan 210, 1046 AB Amsterdam</p>
                        <p>E-mail address: info@techbyte.nl</p>
                        <p>Chamber of Commerce number: 80337422</p>
                        <p>VAT number: NL861636168B01</p>
                
                </div>
                <div className="container">
                    <h2 className="subtitle">Article 3 - Applicability</h2>
                    {/* <p>The acceptance of an agreement between the Purchaser and Unity Trading BV is only considered complete when Unity Trading BV has received the purchase order of Purchaser and subsequently sent a proforma invoice.</p> */}
                    <ul>
                        <li>
                        These general terms and conditions apply to every offer from the Entrepreneur (Techbyte.nl) and to every distance agreement concluded between Entrepreneur (Techbyte.nl) and Entrepreneur.
                        </li>
                        <li>
                        Before the distance contract is concluded, the text of these general terms and conditions will be made available to the Entrepreneur. If this is not reasonably possible, the Entrepreneur (Techbyte.nl) will indicate before the distance contract is concluded how the general terms and conditions can be viewed at the Entrepreneur (Techbyte.nl) and that they will be available at the request of the Entrepreneur. be sent free of charge as soon as possible.
                        </li>
                        <li>
                        If the distance contract is concluded electronically, contrary to the previous paragraph and before the distance contract is concluded, the text of these general terms and conditions can be made available to the Entrepreneur electronically in such a way that the Entrepreneur can can be easily stored on a durable data carrier. If this is not reasonably possible, before the distance contract is concluded, it will be indicated where the general terms and conditions can be inspected electronically and that they will be sent free of charge at the request of the Entrepreneur electronically or otherwise.
                        </li>
                        <li>
                        In the event that specific product terms and conditions apply in addition to these general terms and conditions, the second and third paragraphs apply mutatis mutandis and the Entrepreneur can always invoke the applicable provision that is most favorable to him in the event of conflicting terms and conditions.
                        </li>
                    </ul>
                </div>
                <div className="container">
                    <h2 className="subtitle">Article 4 - The offer</h2>
                    {/* <p>All prices are subject to change without notice and will be established at time of order acceptance by Unity Trading BV. Prices for backordered products are not guaranteed.</p>
                    <p>All costs arising from additions and/or amendments to an order by or on behalf of the buyer are entirely for the account of the buyer.</p> */}
                    <ul>
                        <li>If an offer has a limited period of validity or is subject to conditions, this will be explicitly stated in the offer.</li>
                        <li>The offer contains a complete and accurate description of the products offered. The description is sufficiently detailed to enable a proper assessment of the offer by the Entrepreneur. If the Entrepreneur (Techbyte.nl) uses images, these are a true representation of the products offered. Obvious mistakes or errors in the offer do not bind the Entrepreneur (Techbyte.nl).</li>
                        <li>Each offer contains such information that it is clear to the Entrepreneur what the rights and obligations are that are associated with the acceptance of the offer.</li>
                        <li>Techbyte has copyright on the written product texts.</li>
                    </ul>
                </div>
                <div className="container">
                    <h2 className="subtitle">Article 5 - The agreement</h2>
                    {/* <p>5.1 	Specified and agreed delivery times and other times are approximated by Unity Trading and never count as a strict deadline. There can only be a strict deadline if Unity Trading BV specifies an exact delivery date in the order confirmation, which is also explicitly indicated as a strict deadline.</p>
                    <p>5.2	If this expectation turns out to be unjustified, even if this is the result of circumstances that could already be foreseen when the agreement was concluded, the delivery time will be extended by as many days as the delay that occurred has lasted.</p>
                    <p>5.3	The delivery time will also be extended if the buyer changes the order or otherwise delays its execution after the agreement has been concluded.</p>
                    <p>5.4 	If it has been agreed that Unity Trading BV will handle the transportation, Unity Trading BV has the right to select the method of transportation. Any obstacles or temporary difficulties with the chosen method of transportation do not require Unity Trading BV to choose an alternative method, unless otherwise agreed in writing.</p>
                    <p>5.5 	Upon receipt, the purchaser is responsible for inspecting all products and must promptly notify Unity Trading BV, as specified herein, of any discrepancies or refusals to accept the delivered products. Such notification should be sufficiently detailed and explain the reason for refusing the purchased product, within 48hrs after receiving purchased products. If the purchaser fails to provide such notification to Unity Trading BV as specified herein, it shall be deemed that the products have been accepted by the purchaser as of the shipment date.</p> */}
                    <ul>
                        <li>Subject to the provisions of paragraph 4, the agreement is concluded at the time of acceptance by the Entrepreneur of the offer and compliance with the associated conditions.</li>
                        <li>If the Entrepreneur has accepted the offer electronically, the Entrepreneur (Techbyte.nl) will immediately confirm receipt of the acceptance of the offer electronically. As long as the receipt of this acceptance has not been confirmed by the Entrepreneur (Techbyte.nl), the Entrepreneur can dissolve the agreement.</li>
                        <li>If the agreement is concluded electronically, the Entrepreneur (Techbyte.nl) will take appropriate technical and organizational measures to secure the electronic transfer of data and he will ensure a safe web environment. If the Entrepreneur can pay electronically, the Entrepreneur (Techbyte.nl) will observe appropriate security measures.</li>
                        <li>The Entrepreneur (Techbyte.nl) can - within the legal framework - inform himself whether the Entrepreneur can meet his payment obligations, as well as all those facts and factors that are important for a responsible conclusion of the distance contract. If, on the basis of this investigation, the Entrepreneur (Techbyte.nl) has good reasons not to enter into the agreement, he is entitled to refuse an order or request with reasons, or to attach special conditions to the implementation.</li>
                        <li>The Entrepreneur (Techbyte.nl) will send the following information, in writing or in such a way that the Entrepreneur can store it in an accessible manner on a durable data carrier, at the latest upon delivery of the product to the Entrepreneur:</li>
                        <li>The visiting address of the establishment of the Entrepreneur (Techbyte.nl) where the Entrepreneur can go with complaints;</li>
                        <li>The conditions under which and the manner in which the Entrepreneur can make use of the right of withdrawal, or a clear statement regarding the exclusion of the right of withdrawal;</li>
                        <li>The information about warranties and existing after-sales service;</li>
                        <li>The price including all taxes of the product; where applicable, the costs of delivery; and the method of payment, delivery or performance of the distance contract;</li>
                        <li>If the Entrepreneur has a right of withdrawal, the model form for withdrawal.</li>
                    </ul>

                </div>
                <div className="container">
                    <h2 className="subtitle">Article 6 – Reflection period</h2>
                    {/* <p>6.1	The buyer is required to settle Unity Trading BV's invoices within 7 days from the invoice date by depositing or transferring the payment to the bank account specified on the invoice, unless otherwise agreed upon. The value date indicated on Unity Trading BV's bank statements will be considered the date of payment. </p>
                    <p>6.2	The buyer must fulfill payment for Unity Trading BV's invoices in accordance with Article 6.1, without exercising any right of suspension.</p>
                    <p>6.3	The buyer must settle Unity Trading BV's invoices in accordance with Article 6.1 without any discount or offset.</p>
                    <p>6.4	If the limit mentioned in Article 6.1 exceeds the specified period, the buyer is obligated, without prior notice of default, to pay a credit limitation surcharge amounting to 2% of the outstanding invoice amount.</p>
                    <p>6.5	If the limit mentioned in Article 6.1 exceeds the aforementioned period, the buyer will be considered in default without prior notice of default and will be liable for an interest rate of 1% per month on the outstanding invoice amount. The interest will be calculated based on the accumulated amount after each month, starting from the end of the initial month.</p>
                    <p>6.6	Unity Trading BV does not offer credit terms to buyers. The buyer acknowledges that Unity Trading BV reserves the right to refuse credit and requires payment of the applicable purchase price prior to shipment. The buyer must promptly inform Unity Trading BV of any changes to their name, address, or the sale of a significant portion of their assets. By submitting a purchase order, the buyer grants Unity Trading BV a security interest in the products to secure full payment. The buyer agrees to complete any additional documentation necessary to perfect such security interest.</p>
                    <p>6.7	All judicial and extrajudicial costs incurred by the contractor for the collection of outstanding payments will be borne by the client. The judicial costs will correspond to the actual expenses incurred by the contractor. </p>
                    <p>6.8	If the buyer fails to make timely payment of an invoice, Unity Trading BV reserves the right, in addition to any other legal or equitable remedies available, to delay or cancel future deliveries and/or revoke any quantity discounts granted to the buyer. The buyer will be responsible for covering all collection costs, including reasonable attorney's fees.</p>
                    <p>6.9	In the event of non-payment or late payment, application for payment suspension, bankruptcy or debt restructuring, attachment at the expense of the buyer, merger or demerger, change in direct or indirect control over the buyer's company, liquidation of the purchaser's company, and/or apparent deterioration in the buyer's creditworthiness, Unity Trading BV has the right to terminate all existing agreements, or parts thereof that are yet to be performed, through extrajudicial declaration, without the need for judicial intervention, and without prejudice to its right to seek compensation.</p>
                    <p>6.10	In the cases mentioned in the preceding paragraph, any claims held by Unity Trading BV against the buyer, regardless of the reason, become immediately due and payable in full.</p>
                    <p>6.11	Unity Trading BV has the right, upon or after entering into an agreement with the buyer, prior to (further) performance, to request security from the buyer to ensure the fulfillment of both payment and other obligations.</p> */}
                    <p>By products:</p>
                    <ul>
                        <li>
                        The Entrepreneur can dissolve an agreement regarding the purchase of a product during a cooling-off period of 14 days without giving reasons. The Entrepreneur (Techbyte.nl) may ask the Entrepreneur about the reason for withdrawal, but not oblige him to state his reason(s).
                        </li>
                        <li>The cooling-off period referred to in paragraph 1 commences on the day after the Entrepreneur, or a third party designated in advance by the Entrepreneur, who is not the carrier, has received the product, or:</li>
                        <li>If the Entrepreneur has ordered several products in the same order: the day on which the Entrepreneur, or a third party designated by him, has received the last product. The Entrepreneur (Techbyte.nl) may, provided he has clearly informed the Entrepreneur about this prior to the ordering process, refuse an order for several products with different delivery times.</li>
                        <li>If the delivery of a product consists of several shipments or parts: the day on which the Entrepreneur, or a third party designated by him, has received the last shipment or the last part;</li>
                        <li>In the case of agreements for regular delivery of products during a certain period: the day on which the Entrepreneur, or a third party designated by him, has received the first product.</li>
                    </ul>


                </div>
                <div className="container">
                    <h2 className="subtitle">Article 7 - Obligations of the Entrepreneur (Techbyte.nl) in case of returns</h2>
                    <p>7.1	Force majeure, as referred to in this article, shall mean circumstances that hinder the performance of the agreement by Unity Trading BV and are beyond the control of Unity Trading BV.</p>
                    {/* <p>7.2	Force majeure, as mentioned in the preceding clause, also includes international conflicts, violent or armed conflicts, actions taken by any domestic, foreign, or international government, boycott actions, labor disputes involving third parties or Unity Trading BV's own personnel, disruptions in electricity supply, communication connections, or other equipment or software of Unity Trading BV, and delays in the delivery of goods by third parties to Unity Trading BV. In the event of such circumstances, Unity Trading BV shall take reasonable measures to limit the buyer's damages to the extent practicable.</p>
                    <p>7.3	During force majeure, Unity Trading BV's delivery and other obligations shall be suspended. If the period during which the performance of Unity Trading BV's obligations is prevented by force majeure exceeds two months, both parties shall have the right to terminate the agreement without judicial intervention, without any obligation to compensate for damages and costs.</p>
                    <p>7.4	If Unity Trading BV has already partially fulfilled its obligations at the onset of force majeure, or can only partially fulfill its obligations, Unity Trading BV is entitled to separately invoice the already delivered or deliverable part, and the buyer is obligated to settle this invoice as if it were a separate agreement.</p> */}
                    <ul>
                        <li>If the Entrepreneur (Techbyte.nl) makes the notification of withdrawal by the Entrepreneur electronically possible, he will immediately send a confirmation of receipt after receipt of this notification.</li>
                        <li>The Entrepreneur (Techbyte.nl) will reimburse all payments made by the Entrepreneur, including any delivery costs charged by the Entrepreneur (Techbyte.nl) for the returned product, immediately but within 14 days following the day on which the Entrepreneur notifies him of the withdrawal. Unless the Entrepreneur (Techbyte.nl) offers to collect the product himself, he may wait with paying back until he has received the product or until the Entrepreneur demonstrates that he has returned the product, whichever is the earlier.</li>
                        <li>The Entrepreneur (Techbyte.nl) uses the same means of payment that the Entrepreneur used for reimbursement, unless the Entrepreneur agrees to a different method. The refund is free of charge for the Entrepreneur.</li>
                        <li>If the Entrepreneur has opted for a more expensive method of delivery than the cheapest standard delivery, the Entrepreneur (Techbyte.nl) does not have to repay the additional costs for the more expensive method.</li>
                        
                    </ul>

                    {/* <p className="lead">“ These terms and conditions constitute the official terms and conditions of sale between Unity Trading BV and the Purchaser. Wherefor, the parties acknowledge that they have carefully reviewed and comprehended this Agreement, and willingly and voluntarily assume the duties and obligations stated herein. ”</p> */}
                </div>

                <div className="container">
                    <h2 className="subtitle">Article 8 - The price</h2>
                    {/* <p>7.1	Force majeure, as referred to in this article, shall mean circumstances that hinder the performance of the agreement by Unity Trading BV and are beyond the control of Unity Trading BV.</p>
                    <p>7.2	Force majeure, as mentioned in the preceding clause, also includes international conflicts, violent or armed conflicts, actions taken by any domestic, foreign, or international government, boycott actions, labor disputes involving third parties or Unity Trading BV's own personnel, disruptions in electricity supply, communication connections, or other equipment or software of Unity Trading BV, and delays in the delivery of goods by third parties to Unity Trading BV. In the event of such circumstances, Unity Trading BV shall take reasonable measures to limit the buyer's damages to the extent practicable.</p>
                    <p>7.3	During force majeure, Unity Trading BV's delivery and other obligations shall be suspended. If the period during which the performance of Unity Trading BV's obligations is prevented by force majeure exceeds two months, both parties shall have the right to terminate the agreement without judicial intervention, without any obligation to compensate for damages and costs.</p>
                    <p>7.4	If Unity Trading BV has already partially fulfilled its obligations at the onset of force majeure, or can only partially fulfill its obligations, Unity Trading BV is entitled to separately invoice the already delivered or deliverable part, and the buyer is obligated to settle this invoice as if it were a separate agreement.</p>

                    <p className="lead">“ These terms and conditions constitute the official terms and conditions of sale between Unity Trading BV and the Purchaser. Wherefor, the parties acknowledge that they have carefully reviewed and comprehended this Agreement, and willingly and voluntarily assume the duties and obligations stated herein. ”</p> */}
                    <ul>
                        <li>During the period of validity stated in the offer, the prices of the products offered will not be increased, except for price changes as a result of changes in VAT rates.</li>
                        <li>Contrary to the previous paragraph, the Entrepreneur (Techbyte.nl) can offer products whose prices are subject to fluctuations in the financial market and over which the Entrepreneur (Techbyte.nl) has no influence, with variable prices. This dependence on fluctuations and the fact that any stated prices are target prices are stated in the offer.</li>
                        <li>The prices stated in the range of products include VAT.</li>
                        <li>All prices are subject to printing and typographical errors. No liability is accepted for the consequences of printing and typesetting errors. In the event of printing and typesetting errors, the Entrepreneur (Techbyte.nl) is not obliged to deliver the product according to the incorrect price. Naturally, the Entrepreneur's obligation to investigate applies here.</li>
                    </ul>

                </div>

                <div className="container">
                    <h2 className="subtitle">Article 9 - Compliance agreement</h2>
                    {/* <p>7.1	Force majeure, as referred to in this article, shall mean circumstances that hinder the performance of the agreement by Unity Trading BV and are beyond the control of Unity Trading BV.</p>
                    <p>7.2	Force majeure, as mentioned in the preceding clause, also includes international conflicts, violent or armed conflicts, actions taken by any domestic, foreign, or international government, boycott actions, labor disputes involving third parties or Unity Trading BV's own personnel, disruptions in electricity supply, communication connections, or other equipment or software of Unity Trading BV, and delays in the delivery of goods by third parties to Unity Trading BV. In the event of such circumstances, Unity Trading BV shall take reasonable measures to limit the buyer's damages to the extent practicable.</p>
                    <p>7.3	During force majeure, Unity Trading BV's delivery and other obligations shall be suspended. If the period during which the performance of Unity Trading BV's obligations is prevented by force majeure exceeds two months, both parties shall have the right to terminate the agreement without judicial intervention, without any obligation to compensate for damages and costs.</p>
                    <p>7.4	If Unity Trading BV has already partially fulfilled its obligations at the onset of force majeure, or can only partially fulfill its obligations, Unity Trading BV is entitled to separately invoice the already delivered or deliverable part, and the buyer is obligated to settle this invoice as if it were a separate agreement.</p>

                    <p className="lead">“ These terms and conditions constitute the official terms and conditions of sale between Unity Trading BV and the Purchaser. Wherefor, the parties acknowledge that they have carefully reviewed and comprehended this Agreement, and willingly and voluntarily assume the duties and obligations stated herein. ”</p> */}
                    <ul>
                        <li>The Entrepreneur (Techbyte.nl) guarantees that the products comply with the agreement, the specifications stated in the offer, the reasonable requirements of soundness and/or usability and the legal provisions existing on the date of the conclusion of the agreement and /or government regulations. If agreed, the Entrepreneur (Techbyte.nl) also guarantees that the product is suitable for other than normal use.</li>
                        <li>An additional guarantee provided by the Entrepreneur (Techbyte.nl), its supplier, manufacturer or importer never limits the legal rights and claims that the Entrepreneur can assert against the Entrepreneur (Techbyte.nl) under the agreement if the Entrepreneur (Techbyte .nl) has failed to fulfill its part of the agreement.</li>
                    </ul>
                </div>

                <div className="container">
                    <h2 className="subtitle">Article 10 - Delivery and execution</h2>
                    {/* <p>7.1	Force majeure, as referred to in this article, shall mean circumstances that hinder the performance of the agreement by Unity Trading BV and are beyond the control of Unity Trading BV.</p>
                    <p>7.2	Force majeure, as mentioned in the preceding clause, also includes international conflicts, violent or armed conflicts, actions taken by any domestic, foreign, or international government, boycott actions, labor disputes involving third parties or Unity Trading BV's own personnel, disruptions in electricity supply, communication connections, or other equipment or software of Unity Trading BV, and delays in the delivery of goods by third parties to Unity Trading BV. In the event of such circumstances, Unity Trading BV shall take reasonable measures to limit the buyer's damages to the extent practicable.</p>
                    <p>7.3	During force majeure, Unity Trading BV's delivery and other obligations shall be suspended. If the period during which the performance of Unity Trading BV's obligations is prevented by force majeure exceeds two months, both parties shall have the right to terminate the agreement without judicial intervention, without any obligation to compensate for damages and costs.</p>
                    <p>7.4	If Unity Trading BV has already partially fulfilled its obligations at the onset of force majeure, or can only partially fulfill its obligations, Unity Trading BV is entitled to separately invoice the already delivered or deliverable part, and the buyer is obligated to settle this invoice as if it were a separate agreement.</p>

                    <p className="lead">“ These terms and conditions constitute the official terms and conditions of sale between Unity Trading BV and the Purchaser. Wherefor, the parties acknowledge that they have carefully reviewed and comprehended this Agreement, and willingly and voluntarily assume the duties and obligations stated herein. ”</p> */}
                    <ul>
                        <li>The Entrepreneur (Techbyte.nl) will take the greatest possible care when receiving and executing orders for products.</li>
                        <li>The place of delivery is the address that the Entrepreneur has made known to the Entrepreneur (Techbyte.nl).</li>
                        <li>With due observance of what is stated in article 4 of these general terms and conditions, the Entrepreneur (Techbyte.nl) will execute accepted orders expeditiously but at the latest within 30 days, unless a different delivery period has been agreed. If delivery is delayed, or if an order cannot or only partially be executed, the Entrepreneur will be notified of this no later than 30 days after he has placed the order. In that case, the Entrepreneur has the right to dissolve the agreement without costs.</li>
                        <li>After dissolution in accordance with the previous paragraph, the Entrepreneur (Techbyte.nl) will immediately refund the amount that the Entrepreneur has paid.</li>
                        <li>The risk of damage and/or loss of products rests with the Entrepreneur (Techbyte.nl) until the moment of delivery to the Entrepreneur or a pre-designated representative announced to the Entrepreneur (Techbyte.nl), unless expressly agreed otherwise.</li>
                    </ul>

                </div>

                <div className="container">
                    <h2 className="subtitle">Article 11 - Payment</h2>
                    {/* <p>7.1	Force majeure, as referred to in this article, shall mean circumstances that hinder the performance of the agreement by Unity Trading BV and are beyond the control of Unity Trading BV.</p>
                    <p>7.2	Force majeure, as mentioned in the preceding clause, also includes international conflicts, violent or armed conflicts, actions taken by any domestic, foreign, or international government, boycott actions, labor disputes involving third parties or Unity Trading BV's own personnel, disruptions in electricity supply, communication connections, or other equipment or software of Unity Trading BV, and delays in the delivery of goods by third parties to Unity Trading BV. In the event of such circumstances, Unity Trading BV shall take reasonable measures to limit the buyer's damages to the extent practicable.</p>
                    <p>7.3	During force majeure, Unity Trading BV's delivery and other obligations shall be suspended. If the period during which the performance of Unity Trading BV's obligations is prevented by force majeure exceeds two months, both parties shall have the right to terminate the agreement without judicial intervention, without any obligation to compensate for damages and costs.</p>
                    <p>7.4	If Unity Trading BV has already partially fulfilled its obligations at the onset of force majeure, or can only partially fulfill its obligations, Unity Trading BV is entitled to separately invoice the already delivered or deliverable part, and the buyer is obligated to settle this invoice as if it were a separate agreement.</p>

                    <p className="lead">“ These terms and conditions constitute the official terms and conditions of sale between Unity Trading BV and the Purchaser. Wherefor, the parties acknowledge that they have carefully reviewed and comprehended this Agreement, and willingly and voluntarily assume the duties and obligations stated herein. ”</p> */}
                    <ul>
                        <li>Unless otherwise stipulated in the agreement or additional conditions, the amounts owed by the Entrepreneur must be paid within 14 days after the reflection period commences, or in the absence of a reflection period within 14 days after the conclusion of the agreement.</li>
                        <li>When selling products to Entrepreneurs, the Entrepreneur may never be obliged to pay more than 50% in advance in the general terms and conditions. When advance payment has been stipulated, the Entrepreneur cannot assert any rights regarding the execution of the relevant order before the stipulated advance payment has been made.</li>
                        <li>The Entrepreneur is obliged to immediately report inaccuracies in payment details provided or stated to the Entrepreneur (Techbyte.nl).</li>
                        <li>If the Entrepreneur does not meet his payment obligation(s) in time, this will be due after the Entrepreneur (Techbyte.nl) has pointed out the late payment and the Entrepreneur (Techbyte.nl) has granted the Entrepreneur a period of 14 days. in order to still meet his payment obligations, after payment has not been made within this 14-day period, the statutory interest is due on the amount still due and the Entrepreneur (Techbyte.nl) is entitled to charge the extrajudicial collection costs incurred by him .</li>
                        
                    </ul>


                </div>

                <div className="container">
                    <h2 className="subtitle">Article 12 – applicable law and competent court</h2>
                    {/* <p>7.1	Force majeure, as referred to in this article, shall mean circumstances that hinder the performance of the agreement by Unity Trading BV and are beyond the control of Unity Trading BV.</p>
                    <p>7.2	Force majeure, as mentioned in the preceding clause, also includes international conflicts, violent or armed conflicts, actions taken by any domestic, foreign, or international government, boycott actions, labor disputes involving third parties or Unity Trading BV's own personnel, disruptions in electricity supply, communication connections, or other equipment or software of Unity Trading BV, and delays in the delivery of goods by third parties to Unity Trading BV. In the event of such circumstances, Unity Trading BV shall take reasonable measures to limit the buyer's damages to the extent practicable.</p>
                    <p>7.3	During force majeure, Unity Trading BV's delivery and other obligations shall be suspended. If the period during which the performance of Unity Trading BV's obligations is prevented by force majeure exceeds two months, both parties shall have the right to terminate the agreement without judicial intervention, without any obligation to compensate for damages and costs.</p>
                    <p>7.4	If Unity Trading BV has already partially fulfilled its obligations at the onset of force majeure, or can only partially fulfill its obligations, Unity Trading BV is entitled to separately invoice the already delivered or deliverable part, and the buyer is obligated to settle this invoice as if it were a separate agreement.</p>

                    <p className="lead">“ These terms and conditions constitute the official terms and conditions of sale between Unity Trading BV and the Purchaser. Wherefor, the parties acknowledge that they have carefully reviewed and comprehended this Agreement, and willingly and voluntarily assume the duties and obligations stated herein. ”</p> */}
                    <ul>
                        <li>Only Dutch law applies to agreements between the Entrepreneur (Techbyte.nl) and the Entrepreneur to which these general terms and conditions apply.</li>
                        <li>Disputes between the Entrepreneur and the Entrepreneur (Techbyte.nl) about the conclusion or performance of agreements with regard to products to be delivered or delivered by this Entrepreneur (Techbyte.nl), with due observance of the provisions below, can be settled by both the Entrepreneur and the Entrepreneur (Techbyte.nl) can only be submitted to the District Court of Noord-Holland, location Haarlem.</li>
                    </ul>
                </div>

                <div className="container">
                    <h2 className="subtitle">Article 13 - Additional or deviating provisions</h2>
                    {/* <p>7.1	Force majeure, as referred to in this article, shall mean circumstances that hinder the performance of the agreement by Unity Trading BV and are beyond the control of Unity Trading BV.</p>
                    <p>7.2	Force majeure, as mentioned in the preceding clause, also includes international conflicts, violent or armed conflicts, actions taken by any domestic, foreign, or international government, boycott actions, labor disputes involving third parties or Unity Trading BV's own personnel, disruptions in electricity supply, communication connections, or other equipment or software of Unity Trading BV, and delays in the delivery of goods by third parties to Unity Trading BV. In the event of such circumstances, Unity Trading BV shall take reasonable measures to limit the buyer's damages to the extent practicable.</p>
                    <p>7.3	During force majeure, Unity Trading BV's delivery and other obligations shall be suspended. If the period during which the performance of Unity Trading BV's obligations is prevented by force majeure exceeds two months, both parties shall have the right to terminate the agreement without judicial intervention, without any obligation to compensate for damages and costs.</p>
                    <p>7.4	If Unity Trading BV has already partially fulfilled its obligations at the onset of force majeure, or can only partially fulfill its obligations, Unity Trading BV is entitled to separately invoice the already delivered or deliverable part, and the buyer is obligated to settle this invoice as if it were a separate agreement.</p>

                    <p className="lead">“ These terms and conditions constitute the official terms and conditions of sale between Unity Trading BV and the Purchaser. Wherefor, the parties acknowledge that they have carefully reviewed and comprehended this Agreement, and willingly and voluntarily assume the duties and obligations stated herein. ”</p> */}
                    <ul>
                        <li>Additional or deviating provisions from these general terms and conditions may not be to the detriment of the Entrepreneur and must be recorded in writing or in such a way that they can be stored by the Entrepreneur in an accessible manner on a durable data carrier.</li>
                    </ul>
                </div>
                <div className="container">
                    <h2 className="subtitle">Article 14 - Modification of the General Terms and Conditions</h2>
                    
                    <ul>
                        <li>Changes to these terms and conditions are only effective after they have been published in an appropriate manner, on the understanding that in the event of applicable changes during the term of an offer, the provision most favorable to the Entrepreneur will prevail.</li>
                    </ul>
                </div>
            </div>{/* End .about-section */}
        </main>
    )
}

export default TermAndCond;