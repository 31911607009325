import React from 'react'

export default function Minipopup(props) { 
  const product = props.productInfo;
  return (
    <div className="mini-popup error-pop succes-pop">
      <div className="content cont-pop"> 
      <table className="table table-wishlist mb-0"> 
        <tbody>
                    <tr className="product-row" >
                        <td>
                            <figure className="product-image-container">
                                <a  className="product-image">
                                    <img src={product?.imgPath} alt="product" />
                                </a>
                            </figure>
                        </td>
                        <td>
                            <h5 className="product-title">
                                <a>{product?.product}</a>
                            </h5>
                            <p>Product toegevoegd aan winkelwagen</p>
                        </td> 
                 </tr>
        </tbody>
        </table>
         <a href="/cart" className="btn btn-quickview1 mt-1 mt-md-0 pop-cart" title="View Cart">Zie Winkelwagen</a> 
      </div>
    </div>
  )
}
