import React, { useState,useEffect,useRef } from 'react'
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Alert} from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import Loader from '../common/Loader';
 
    
                          
export default function Register(props) {

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const user = JSON.parse(localStorage.getItem('user'));
  async function checkregister() {
  if (!user?.token) {
  return <Navigate to="/register" />;
  } 
  }
  const [companyName, setCompany_Name] = useState();
  const [personName, setPerson_Name] = useState();
  const [email, setPerson_Email] = useState();
  const [phone, setPerson_Phonenumber] = useState();
  const [comtitle, setPerson_comtitle] = useState();
  const [password, setPerson_Password] = useState();
  const [annexure, setPerson_annexure] = useState();
  const [address, setPerson_Address] = useState();
  const [country, setPerson_Country] = useState();
  const [city, setPerson_City] = useState();
  const [state, setPerson_State] = useState();
  const [zipcode, setPerson_Zipcode] = useState();
  const [shippingAddress, setPerson_Shipping_Address] = useState('');
  const [shippingCountry, setpPerson_Shipping_Country] = useState();
  const [shippingCity, setPerson_Shipping_City] = useState();
  const [shippingState, setPerson_Shipping_State] = useState();
  const [shippingZipcode, setPerson_Shipping_Zipcode] = useState();
  const [commerceNumber, setPerson_Commerce_Number] = useState();
  const [vatNumber, setPerson_Vat_Number] = useState();
  const [bankName, setPerson_Bank_Name] = useState();
  const [accountNumber, setPerson_Account_Number] = useState();
  const [swiftNumber, setPerson_Swift_Number] = useState();
  const [title, setPerson_title] = useState();
  const [AlertText, setAlertText] = useState();
  const [Variant, setVariant] = useState("");
  const [hide, setHide] = useState(false);
  const [commerceNumberAnnex, setcommerceNumberAnnex] = useState(null);
  const [file, setFile] = useState();
  const [passwordError, setPasswordError] = useState("");
  const [cPwd, setCPwd] = useState("");
  const [cPwdErr, setCPwdErr] = useState("");
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const regurlOne = process.env.REACT_APP_BASE_URL + 'registration-step-one';
  const regurlTwo = process.env.REACT_APP_BASE_URL + 'registration-step-two';
  const [loader, setLoader] = useState(false);
  const [signupStatus, setSignupStatus] = useState(false);
  const [thuiskopieStatus, setThuiskopieStatus] = useState(false);
  const [thuiskopieNumber, setThuiskopieNumber] = useState();

  const [shippingStatus, setShippingStatus] = useState(false);

  const toggleHide = () => {
  setHide('');
  };
 
  const handleChange= (event) => {
    setcommerceNumberAnnex(event.target.files[0])
  }

  const registerUser=(credentials) =>{
  const url = process.env.REACT_APP_BASE_URL + 'registration';
    return fetch(url , {
      method: 'POST',
      headers: {

        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
  .then(data => data.json())
  }

  const handleThuiskopieConfirm = (e)=>{
    if(e.target.checked){
      setThuiskopieStatus(true);
    }else{
      setThuiskopieStatus(false);
      setThuiskopieNumber('')
    }
  }
  const handleregOneSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append('companyName', companyName);
    formData.append('personName', personName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('password', password);
    formData.append('title', title);
    axios.post(regurlOne, formData)
      .then((response) => {
          if (response.data.success == 1) {
            localStorage.setItem('tempUserId',response.data.body.userId);
            setSignupStatus(true);
            setAlertText(response.data.message);
            setVariant("success");
            setLoader(false);
          }
          else{
            setAlertText(response.data.message);
            setVariant("danger");
            setLoader(false);
        }
    })
    .catch((error) => {});
    };

  const handleregSubmit = (e) => {
  e.preventDefault();
  setLoader(true);
  const formData = new FormData();
  formData.append('userId', localStorage.getItem('tempUserId'));
  formData.append('address', address);
  formData.append('country', country);
  formData.append('city', city);
  formData.append('state', state);
  formData.append('zipcode', zipcode);
  formData.append('shippingAddress', shippingAddress);
  formData.append('shippingCountry', shippingCountry);
  formData.append('shippingCity', shippingCity);
  formData.append('shippingState', shippingState);
  formData.append('shippingZipcode', shippingZipcode);
  formData.append('commerceNumber', commerceNumber);
  formData.append('vatNumber', vatNumber);
  formData.append('accountNumber', accountNumber);
  formData.append('bankName', bankName);
  formData.append('swiftNumber', swiftNumber);
  formData.append('commerceNumberAnnex',commerceNumberAnnex);
  formData.append('thuiskopieStatus',thuiskopieStatus);
  formData.append('thuiskopieNumber',thuiskopieNumber);
  formData.append('shippingStatus',shippingStatus);

  axios.post(regurlTwo, formData)
    .then((response) => {
        if (response.data.success == 1) {
          setAlertText(response.data.message);
          setVariant("success");
          setLoader(false);
          window.location.href = response.data.body.docusignUrl;
        }
        else{
          setAlertText(response.data.message);
          setVariant("danger");
          setLoader(false);

      }
  })
  .catch((error) => {});
  };

  function handleSelectCountryChange(event) {
  setPerson_Country(event.target.value);
  }

  const [select, setSelected]  = useState('');
  const [optionList,setOptionList] = useState([]);

  const cuntryurl = process.env.REACT_APP_BASE_URL + 'get-countries';

  const fetchcData = () => {
  axios
  .get(cuntryurl)
  .then((response) => {

  const { data } = response;
  if(response.data.success === 1){
  //check the api call is success by stats code 200,201 ...etc
  setOptionList(data.body)
  }else{
  //error handle section 
  }
  })
  .catch((error) => {});
  };
  
  const handleshippigaddress = async (e) => {
  
    if(e.target.checked){
      setShippingStatus(true);
      setPerson_Shipping_Address('');
      setPerson_Shipping_City('');
      setpPerson_Shipping_Country('');
      setPerson_Shipping_State('');
      setPerson_Shipping_Zipcode('');
    }
    else{    
      setShippingStatus(false);  
      setPerson_Shipping_Address(address);
      setPerson_Shipping_City(city);
      setpPerson_Shipping_Country(country);
      setPerson_Shipping_State(state);
      setPerson_Shipping_Zipcode(zipcode);
  }  
  };

  useEffect(()=>{
  toggleHide();
  },[])
  useEffect(()=>{
  fetchcData();
  },[])
  useEffect(()=>{
  checkregister();
  },[])    

return ( 
  <main className="main">
    <div className="page-header">
      <div className="container d-flex flex-column align-items-center">
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
        <div className="container">
        <ol className="breadcrumb">
        <li className="breadcrumb-item"><a href="/" onClick={() => localStorage.setItem("menuItem", "")}>Home</a></li>
        </ol>
        </div>
        </nav>
      <h1>Klant Worden</h1>
      </div>
    </div>
    <div className="container login-container">
    <Loader loading={loader} />
    <div className="row">   
    <div className='col-lg-8 mx-auto register-checklist'>
    <p  ><strong>Redenen om een account aan te maken</strong></p>
    <ul>
    <li><i className="fas fa-check"></i><p >Beheer al uw bestellingen en retouren.</p></li>
    <li><i className="fas fa-check"></i><p >Bestel sneller met opgeslagen adresgegevens.</p></li>
    <li><i className="fas fa-check"></i><p  >U heeft op elk apparaat toegang tot uw winkelwagen.</p></li>
    </ul> 
    </div> 
    </div>

    <div className="row">
    <div className="col-lg-10 mx-auto">
    <div className="row">
    <div className="App col-md-12">  
    <Alert variant={Variant} show={AlertText? true : false}>
    {AlertText}
    </Alert> 
    </div>  
    <div className="col-md-12 register-style">
    <div className="heading mb-1 text-center">
    <h2 className="title">Basis Informatie</h2>
    </div>             

    <form className="mb-0" encType='multipart/form-data'>
    { signupStatus == false ? 
    <>
        <div className='form-styl'>
          <div className='inner-style'>
          <label for="login-company-name" className="form-label  ">Bedrijfsnaam<span className="required">*</span></label>
          <input type="text" className="form-control" id="companyName" name="companyName" onChange={e => setCompany_Name(e.target.value)} required />
          </div>
          <div className='inner-style'>
            <label for="login-phonenumber" className="form-label text-center">Functie<span className="required">*</span></label>
            <input type="text" className="form-control" id="title" name="title" onChange={e => setPerson_title(e.target.value)} required />
          </div>
        </div>
        <div className='form-styl-fullwidth'>
          <label for="login-person-name" className="form-label text-center">Persoon Naam<span className="required">*</span></label>
          <input type="text" className="form-control" id="personName" name="personName" onChange={e => setPerson_Name(e.target.value)} required />
        </div>
        <div className='form-styl'>
          <div className='inner-style'>
            <label for="login-email">E-mail Adres<span className="required">*</span></label>
            <input type="email" className="form-control" id="email" name="email" onChange={e => setPerson_Email(e.target.value)} required />
          </div>
          <div className='inner-style'>
            <label for="login-phonenumber" className="form-label text-center">Telefoon Nummer<span className="required">*</span></label>
            <input type="tel" className="form-control" id="phone" name="phone" onChange={e => setPerson_Phonenumber(e.target.value)} required />
          </div>
        </div>
        <div className='form-styl-fullwidth'>
          <label for=" login-password">Wachtwoord<span className="required"> *</span></label>
          <input type="password" className="form-control " id="password"  name="password" 
          onChange={e => {
            setPerson_Password(e.target.value);
            const validateNewPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/;
            let validatedNewPassword = true;
            if (!validateNewPassword.test(e.target.value)) {
              validatedNewPassword = false
            } else {
              validatedNewPassword = true
            }
            if (!e.target.value) {
              setPasswordError('Voer wachtwoord in alstublieft.');
              // setDisableSubmitBtn(true);
            } else if (!validatedNewPassword) {            
              setPasswordError('Het wachtwoord moet minimaal zes tekens en maximaal 16 tekens lang zijn. Het moet één kleine letter, één hoofdletter en één speciaal teken bevatten')
              // setDisableSubmitBtn(true);
            } else {
              setPasswordError("");
              // setDisableSubmitBtn(false);
            }
          }}
          required />
          {passwordError && <span className="err" style={{ color: "red" }} >{passwordError}</span>}
        </div>
        <div className='form-styl-fullwidth'>
        <label for=" login-password">Bevestig Wachtwoord<span className="required"> *</span></label>
        <input type="password" className="form-control" id="password"  name="password" 
        onChange={e => {
          setCPwd(e.target.value);
          if (!e.target.value) {
            setCPwdErr('Voer wachtwoord in alstublieft.');
            setDisableSubmitBtn(true);
          } else if (e.target.value != password) {            
            setCPwdErr('Bevestig wachtwoord moet overeenkomen met wachtwoord.')
            setDisableSubmitBtn(true);
          } else {
            setCPwdErr("");
            setDisableSubmitBtn(false);
          }
        }}
        required />
        {cPwdErr && <span className="err" style={{ color: "red" }} >{cPwdErr}</span>}
        </div>
        <div className="text-center">
          <button className="btn btn-dark btn-block btn-md" onClick={handleregOneSubmit} disabled={disableSubmitBtn} >Opslaan
          </button>
        </div>
    </>
      :
    <>
        <div className='form-styl-fullwidth'>
        <label for=" login-password">Bedrijfsuitreksel<span className="required"> *</span></label>
        <input type="file" className="form-control" id="commerceNumberAnnex"  name="commerceNumberAnnex" onChange={handleChange}  required/>
        </div>
        <div className='form-styl'>
            <div className='inner-style'>
            <label for="login-vat-number" className="form-label">BTW Nummer<span className="required">*</span></label>
              <input type="text" className="form-control" id="vatNumber" name="vatNumber" onChange={e => setPerson_Vat_Number(e.target.value)} required /> 
            </div>
            <div className='inner-style'>
              <label for="login-commerce-number" className="form-label text-center">KvK Nummer<span className="required">*</span></label>
              <input type="text" className="form-control" id="commerceNumber" name="commerceNumber" onChange={e => setPerson_Commerce_Number(e.target.value)} required />
            </div>
        </div>
        <div className='form-styl-fullwidth  text-center bill-hr'>
        <hr className='hr-style'></hr>  
          <label className='mini-heading'>Factuuradres</label>
        </div>
        <div className='form-styl'>
        <div className='inner-style'>
          <label for="login-address" className="form-label text-center">Adres<span className="required">*</span></label>
          <input type="text" ref={inputRef1} className="form-control" id="address" name="address" onChange={e => setPerson_Address(e.target.value)} required />
        </div>
        <div className='inner-style'>
          <label for="login-country" className="form-label">Land<span className="required">*</span></label>
          <select
              className="form-control"
              id="country" 
              name="country"
              ///ref={inputRef2}
              disabled={false}
              value={country} 
              onChange={handleSelectCountryChange}
              required >
                <option value="">
                Selecteer land
              </option> 
              {optionList.map((item) => (
              <option value={item.id}>
                  {item.name}
              </option>
              ))}
          </select>
        </div>
        </div>

        <div className='form-styl'>
            <div className='inner-style'>
              <label for="login-city" className="form-label ">Stad<span className="required">*</span></label>
              <input type="text" ref={inputRef3} className="form-control " id="city" name="city" onChange={e => setPerson_City(e.target.value)} required />

            </div>
            <div className='inner-style'>
              <label for="login-state" className="form-label text-center">Staat</label>
              <input type="text" ref={inputRef4} className="form-control" id="state" name="state" onChange={e => setPerson_State(e.target.value)} />
            </div>
        </div>
        <div className='form-styl-fullwidth'>                    
        <label for="login-state" className="form-label text-center">Postcode<span className="required">*</span></label>
              <input type="text" className="form-control" id="zipcode" name="zipcode" onChange={e => setPerson_Zipcode(e.target.value)} required/>
        </div>
        <div className='form-styl-fullwidth  '>
          <hr className='hr-style'></hr>                    
            <label for="login-zipcode" className="form-label">Afleveradres is anders dan factuuradres: </label>
            <input
              type="checkbox"
              name="copybilling"
              className="largerCheckbox"
              onChange={handleshippigaddress}
            /> 
        </div>
        {shippingStatus == true ? 
        <>

          <div className="shipping-address">
            <div className='form-styl-fullwidth text-center'>                    
              <label for="login-zipcode" className="form-label mini-heading">Afleveradres</label>      
            </div>
            <div className='form-styl'>
            <div className='inner-style'>
              <label for="login-shipping-address" className="form-label text-center">Adres<span className="required">*</span></label>
              <input type="text" className="form-control" value={shippingAddress} id="shippingAddress" name="shippingAddress" onChange={e => setPerson_Shipping_Address(e.target.value)}  required/>
              </div>
            <div className='inner-style'>
              <label for="login-shipping-country" className="form-label text-center">Land<span className="required">*</span></label>
              <select
                  className="form-control"
                  id="shippingCountry" 
                  name="shippingCountry"
                  disabled={false}
                  value={shippingCountry}
                  onChange={e => setpPerson_Shipping_Country(e.target.value)}
                  required
                  >
                  <option value="">
                    Selecteer land
                  </option> 
                    {optionList.map((item) => (
                    <option value={item.id}>
                        {item.name}
                    </option>
                    ))}
              </select>
              </div>

            </div>

            <div className='form-styl'>
            <div className='inner-style'>
              <label for="login-shipping-city" className="form-label">Stad<span className="required">*</span></label>
              <input type="text" className="form-control" value={shippingCity} id="shippingCity" name="shippingCity" onChange={e => setPerson_Shipping_City(e.target.value)}  required/>
            </div>
            <div className='inner-style'>
              <label for="login-shipping-state" className="form-label text-center">Staat</label>
              <input type="text" className="form-control" value={shippingState} id="shippingState" name="shippingState" onChange={e => setPerson_Shipping_State(e.target.value)}  />
            </div>
            </div>
            <div className='form-styl-fullwidth'>                    
              <label for="login-zipcode" className="form-label ">Postcode<span className="required">*</span></label>
              <input type="text" className="form-control" value={shippingZipcode} id="shippingZipcode"  name="shippingZipcode" onChange={e => setPerson_Shipping_Zipcode(e.target.value)} required />
            </div>

          </div>
        </>
        : ''}
        <div className='form-styl-fullwidth'>
        <hr className="hr-style"></hr>
        <div className='form-styl-fullwidth text-center'>                    
            <label for="login-zipcode" className="form-label mini-heading">Betalingsdetails</label>      
        </div>
          <label for="login-bank-name" className="form-label text-center">Bank Naam<span className="required">*</span></label>
          <input type="text" className="form-control mb-2" id="bankName" name="bankName" onChange={e => setPerson_Bank_Name(e.target.value)} required />
        </div>
        <div className='form-styl'>
        <div className='inner-style'>
          <label for="login-account-number" className="form-label">IBAN<span className="required">*</span></label>
          <input type="text" className="form-control" id="accountNumber" name="accountNumber" onChange={e => setPerson_Account_Number(e.target.value)} required />
        </div>
        <div className='inner-style'>
          <label for="login-swift-number" className="form-label text-center">SWIFT Nummer</label>
          <input type="text" className="form-control" id="swiftNumber" name="swiftNumber"  onChange={e => setPerson_Swift_Number(e.target.value)} />
        </div>
        </div>

        <hr className='hr-style'></hr> 
        <div className='form-styl-fullwidth'>
             
            <div className='inner-style'>                  
              <label for="thuiskopie-confirm" className="form-label">Bent u Thuiskopie contractant?</label>
                &nbsp;&nbsp;&nbsp;
              <input
                type="checkbox"
                name="thuiskopie-confirm"
                className="largerCheckbox"
                checked={thuiskopieStatus}
                onChange={handleThuiskopieConfirm}
                id="thuiskopie-confirm"
              /> 
            </div>
        { thuiskopieStatus == true ?
            <>
            <div className='inner-style'>
              <input type="text" className="form-control" name="thuiskopie-number" onChange={e => setThuiskopieNumber(e.target.value)} required/>
              <span style={{fontSize:"12px"}}>vermeld hier uw klantnummer bij Stichting de Thuiskopie.</span>
            </div>
            </>
        :''}
        </div>
        
        <div className="form-footer">
        </div>
        <div className="text-center">
        <button className="btn btn-dark btn-block btn-md" onClick={handleregSubmit} disabled={disableSubmitBtn} >Opslaan
        </button>
        </div>
    </>
    }
    </form>
    </div>
    </div>
    </div>
    </div>
    </div>
  </main>
)
}
