export const authStatus={
	PRODUCT_REQUEST:"PRODUCT_REQUEST",
	PRODUCT_SUCCESS:"PRODUCT_SUCCESS",
	PRODUCT_FAILED:"PRODUCT_FAILED",
	LOGOUT_SUCCESS:"LOGOUT_SUCCESS",
	CART_REQUEST:"CART_REQUEST",
	CART_SUCCESS:"CART_SUCCESS",

	GET_CART_REQUEST:"GET_CART_REQUEST",
	GET_CART_SUCCESS:"GET_CART_SUCCESS",
	REMOVE_CART_REQUEST:"REMOVE_CART_REQUEST",
	REMOVE_CART_SUCCESS:"REMOVE_CART_SUCCESS",

	LOGIN_REQUEST:"LOGIN_REQUEST",
	LOGIN_POPUP:"LOGIN_POPUP",
	LOGIN_POPUP_CLOSE:"LOGIN_POPUP_CLOSE",
	LOGIN_SUCCESS:"LOGIN_SUCCESS",
	LOGIN_FAILED:"LOGIN_FAILED",

	REG_REQUEST:"REG_REQUEST",
	REG_SUCCESS:"REG_SUCCESS",
	REG_FAILED:"REG_FAILED",

	LOGOUT_REQUEST:"LOGOUT_REQUEST",
	LOGOUT_SUCCESS:"LOGOUT_SUCCESS",
	LOGOUT_FAILURE:"LOGOUT_FAILURE"

}

export const vendorStatus={
	vendor_REQUEST:"vendor_REQUEST",
	vendor_SUCCESS:"vendor_SUCCESS",
	vendor_FAILED:"vendor_FAILED",
	vendor_STATUS_REQUEST:"vendor_STATUS_REQUEST",
	vendor_STATUS_SUCCESS:"vendor_STATUS_SUCCESS",
	vendor_STATUS_FAILED:"vendor_STATUS_FAILED",
	vendor_DELETE_REQUEST:"vendor_DELETE_REQUEST",
	vendor_DELETE_SUCCESS:"vendor_DELETE_SUCCESS",
	vendor_DELETE_FAILED:"vendor_DELETE_FAILED",
	vendor_EDIT_REQUEST:"vendor_EDIT_REQUEST",
	vendor_EDIT_SUCCESS:"vendor_EDIT_SUCCESS",
	vendor_EDIT_FAILED:"vendor_EDIT_FAILED",
	vendor_UPDATE_REQUEST:"vendor_UPDATE_REQUEST",
	vendor_UPDATE_SUCCESS:"vendor_UPDATE_SUCCESS",
	vendor_UPDATE_FAILED:"vendor_UPDATE_FAILED",
	vendor_ADD_SUCCESS:"vendor_ADD_SUCCESS",
	vendor_BULK_REQUEST:"vendor_BULK_REQUEST",
	vendor_BULK_SUCCESS:"vendor_BULK_SUCCESS",
	vendor_BULK_FAILED:"vendor_BULK_FAILED",
 }

 export const syncStatus={
 	SYNC_DESCRIPTION_REQUEST:"SYNC_DESCRIPTION_REQUEST",
 	SYNC_DESCRIPTION_SUCCESS:"SYNC_DESCRIPTION_SUCCESS",
 	SYNC_DESCRIPTION_FAILED:"SYNC_DESCRIPTION_FAILED",
 	SYNC_STOCK_REQUEST:"SYNC_STOCK_REQUEST",
 	SYNC_STOCK_SUCCESS:"SYNC_STOCK_SUCCESS",
 	SYNC_STOCK_FAILED:"SYNC_STOCK_FAILED"
 }

 export const categoryStatus={
 	CATEGORY_ADD_REQUEST:"CATEGORY_ADD_REQUEST",
 	CATEGORY_ADD_SUCCESS:"CATEGORY_ADD_SUCCESS",
 	CATEGORY_ADD_FAILED:"CATEGORY_ADD_FAILED",
 	CATEGORY_GET_REQUEST:"CATEGORY_GET_REQUEST",
 	CATEGORY_GET_SUCCESS:"CATEGORY_GET_SUCCESS",
 	CATEGORY_GET_FAILED:"CATEGORY_GET_FAILED",
 	CATEGORY_DELETE_REQUEST:"CATEGORY_DELETE_REQUEST",
 	CATEGORY_DELETE_SUCCESS:"CATEGORY_DELETE_SUCCESS",
 	CATEGORY_DELETE_FAILED:"CATEGORY_DELETE_FAILED",
 	GENDER_REQUEST:"GENDER_REQUEST",
	GENDER_SUCCESS:"GENDER_SUCCESS"
 }

 export const cmsStatus={
 	CMS_REQUEST:"CMS_ADD_REQUEST",
 	CMS_SUCCESS:"CMS_ADD_SUCCESS",
 	CMS_FAILED:"CMS_ADD_FAILED",
 	CMS_GET_REQUEST:"CMS_GET_REQUEST",
 	CMS_GET_SUCCESS:"CMS_GET_SUCCESS",
 	CMS_GET_FAILED:"CMS_GET_FAILED",
 	CMS_DELETE_REQUEST:"CMS_DELETE_REQUEST",
 	CMS_DELETE_SUCCESS:"CMS_DELETE_SUCCESS",
 	CMS_DELETE_FAILED: "CMS_DELETE_FAILED",
 	CMS_UPDATE_REQUEST:"CMS_UPDATE_REQUEST",
 	CMS_UPDATE_SUCCESS:"CMS_UPDATE_SUCCESS",
 	CMS_UPDATE_FAILED: "CMS_UPDATE_FAILED",
 	COUPON_REQUEST:"COUPON_REQUEST",
 	COUPON_EXIST:"COUPON_EXIST",
 	COUPON_SUCCESS:"COUPON_SUCCESS",
 	COUPON_FAILED: "COUPON_FAILED",
 	COUPON_GET_REQUEST:"COUPON_GET_REQUEST",
 	COUPON_GET_SUCCESS:"COUPON_GET_SUCCESS",
 	COUPON_GET_FAILED:"COUPON_GET_FAILED",
 	COUPON_UPDATE_REQUEST:"COUPON_UPDATE_REQUEST",
	COUPON_UPDATE_SUCCESS:"COUPON_UPDATE_SUCCESS",
	COUPON_UPDATE_FAILED:"COUPON_UPDATE_FAILED",
 	COUPON_DELETE_REQUEST:"COUPON_DELETE_REQUEST",
	COUPON_DELETE_SUCCESS:"COUPON_DELETE_SUCCESS",
	COUPON_DELETE_FAILED:"COUPON_DELETE_FAILED"
 }

 export const GetproductVarient={
	VARIANT_REQUEST:"CMS_ADD_REQUEST",
	VARIANT_SUCCESS:"CMS_ADD_SUCCESS"
}

export const cartConstants = {
  ADD_TO_CART_REQUEST: "ADD_TO_CART_REQUEST",
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
  ADD_TO_CART_FAILURE: "ADD_TO_CART_FAILURE",
  RESET_CART: "RESET_CART",

  REMOVE_CART_ITEM_REQUEST: "REMOVE_CART_ITEM_REQUEST",
  REMOVE_CART_ITEM_SUCCESS: "REMOVE_CART_ITEM_SUCCESS",
  REMOVE_CART_ITEM_FAILURE: "REMOVE_CART_ITEM_FAILURE",
  ADD_TO_WISHLIST_SUCCESS: "ADD_TO_WISHLIST_SUCCESS",
  ADD_TO_WISHLIST_FAILURE: "ADD_TO_WISHLIST_FAILURE",
};


export const checkoutStatus = {
  CHECKOUT_REQUEST: "CHECKOUT_REQUEST",
  CHECKOUT_SUCCESS: "CHECKOUT_SUCCESS",
  CHECKOUT_FAILURE: "CHECKOUT_FAILURE"
	
  };