import axios from "axios";
import { useEffect, useState } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { useDispatch, useSelector } from 'react-redux';
import { addToCartAction, getUserCart } from '../../action/cartActions';
import { v4 as uuidv4 } from 'uuid'; 
import axiosIntance from "../../helper/axios";

const Wishlist = () => {
    let salePrice = 0.00;
    let applicableDiscount = JSON.parse(localStorage.getItem("userType"))?.applicableDiscount;
    const [userWishlist, setUSerWishlist] = useState(null);
    const userId = localStorage.getItem("userId");
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const [prodQty, setProdQty] = useState(1);
    const getUserWishlist = async () => {
        const { data } = await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}get-wishlists`, { 
            userId
        });
        setUSerWishlist(data);
    }
    const addToCartHandler = (totalPrice, shippingDetails, item, totalQuantity) => {
        dispatch(addToCartAction({ totalPrice, shippingDetails, item, totalQuantity }));
        window.location.reload();
      }
    useEffect(() => {
        getUserWishlist();
    }, []);


    const removeFromWishlist = async (id) => {
        const obj ={
            "wId":id,
            "userId":userId
        }
        await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}remove-from-wishlist`, obj).then(response=>{
            if(response.data.success==1){
                setMessage(response.data.message);
                getUserWishlist();
            }
        }).catch(err=>{
            console.log('Err',err)
        })
      }


    return (
        <div>
            <main className="main">
                <div className="page-header">
                    <div className="container d-flex flex-column align-items-center">
                        <nav aria-label="breadcrumb" className="breadcrumb-nav">
                            <div className="container">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Verlanglijst
                                    </li>
                                </ol>
                            </div>
                        </nav>

                        <h1>Verlanglijst</h1>
                    </div>
                </div>

                <div className="container text-center wishlist">
                    <div className="wishlist-title">
                        <h2 className="p-2">Mijn Verlanglijst </h2>
                        { message ? 
                        <span style={{color:"#308B7B"}}>{ message }</span>
                        : '' }
                    </div>
                    {
                        userWishlist?.body?.length == 0  ?(
                            <h3 >Verlanglijst is leeg!</h3>
                        ) : (
                    <div className="wishlist-table-container">
                        <table className="table table-wishlist mb-0">

                            <thead>
                                <tr>
                                    <th className="thumbnail-col"></th>
                                    <th className="product-col">Producten</th>
                                    <th className="price-col">Prijs</th>
                                    <th className="status-col">Voorraad Status</th>
                                </tr>
                            </thead>

                            <tbody>

                                {
                                    userWishlist ? (
                                        userWishlist?.body?.map((list, i) => { 
                                            return (
                                                <tr className="product-row" key={i} >
                                                    <td>
                                                        <figure className="product-image-container">
                                                            <a href={`/product-details/${list?.products?.[0]?.slug}`} className="product-image">
                                                                <img src={list?.products?.[0]?.images?.[0]} alt="product" />
                                                            </a>

                                                            <a href="javascript:;" onClick={()=>{removeFromWishlist(list?._id)}} className="btn-remove icon-cancel" title="Product verwijderen"></a>
                                                        </figure>
                                                    </td>
                                                    <td>
                                                        <h5 className="product-title">
                                                            <a href={`/product-details/${list?.products?.[0]?.slug}`}>{list?.products?.[0]?.name}</a>
                                                        </h5>
                                                    </td>
                                                    <td className="price-box">
                                                        {applicableDiscount ?
                                                            <>
                                                                { process.env.REACT_APP_CURRENCY + (Number(parseFloat(list?.products?.[0]?.salePrice["$numberDecimal"]) - ( (applicableDiscount/100) * parseFloat(list?.products?.[0]?.salePrice["$numberDecimal"] ))).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </>
                                                        : 
                                                            <>
                                                                {process.env.REACT_APP_CURRENCY + list?.products?.[0]?.salePrice["$numberDecimal"]}
                                                            </>
                                                        }
                                                        
                                                    </td>
                                                    <td>
                                                        {(list?.products?.[0]?.stockQty > 0 ? <>
                                                            <span className="stock-status">Op Voorraad</span>
                                                        </> : <>
                                                        <span className="stock-status">Uitverkocht</span>
                                                        </>)}
                                                        
                                                        
                                                    </td>
                                                    <td className="action">
                                                        <a href={`/product-details/${list?.products?.[0]?.slug}`} className="btn btn-quickview1 mt-1 mt-md-0">
                                                            Bekijken
                                                        </a>
                                                        
                                                        {(list?.products?.[0]?.stockQty > 0 ? <>
                                                            <button className="btn btn-dark btn-add-cart product-type-simple btn-shop" onClick={(e) => {
                                                                if(applicableDiscount){
                                                                    salePrice = parseFloat(list?.products?.[0]?.salePrice["$numberDecimal"]) - ( (applicableDiscount/100) * parseFloat(list?.products?.[0]?.salePrice["$numberDecimal"]))
                                                                }
                                                                else{
                                                                    salePrice = parseFloat(list?.products?.[0]?.salePrice["$numberDecimal"])
                                                                }
                                                                let totalPrice = (Number(salePrice) * Number(prodQty)).toString();
                                                                let shippingDetails = {};
                                                                let totalQuantity = prodQty; 
                                                                let item = {
                                                                id: uuidv4(),
                                                                itemId: list?.itemid,
                                                                product: list?.products?.[0]?.name,
                                                                quantity: prodQty,
                                                                price: salePrice,
                                                                subTotal: totalPrice,
                                                                size: "",
                                                                imgPath: list?.products?.[0]?.images?.[0],
                                                                availableStock:list?.products?.[0]?.stockQty
                                                                }
                                                                addToCartHandler(totalPrice, shippingDetails, item, totalQuantity)
                                                            }}>
                                                            Toevoegen
                                                        </button>
                                                        </> : '')}
                                                        
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ) : null
                                }

                            </tbody>
                        </table>
                    </div>
                    )
                    }
                    {/* <!-- End .cart-table-container --> */}
                </div>
                {/* <!-- End .container --> */}
            </main>
            {/* <!-- End .main --> */}
        </div>
    )
}


export default Wishlist