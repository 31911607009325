import React,{useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route,useNavigate,useLocation, Navigate } from 'react-router-dom'
import {useDispatch,useSelector} from "react-redux";
import Home from "./component/Home" 
import "./App.css"; 
import Header from './component/common/Header';
import Signin from './component/Pages/Signin';
import Register from './component/Pages/Register';
import ForgetPass from './component/Pages/ForgetPass';
import ResetPassword from './component/Pages/ResetPassword';
import Footer from './component/common/Footer';
import Aboutus from './component/Pages/Aboutus';
import Contactus from './component/Pages/Contactus';
import Myaccount from './component/MyAccount/Myaccount';
import Products from './component/Products/Products';
import Productdetails from './component/Products/Productdetails';
import Regsucceess from './component/Pages/Regsuccess';
import Cart from "./component/Pages/Cart";
import Wishlist from './component/Pages/Wishlist';
import PaymentSuccess from './component/Pages/PaymentSuccess';
import ProductsByCategory from './component/Products/ProductsByCategory';
import ProductsByColor from './component/Products/ProductsByColor';
import ProductsByBrand from './component/Products/ProductsByBrand';
import OrderDetails from './component/MyAccount/OrderDetails';
import TermAndCond from './component/Pages/TermAndCond';
import Checkout from './component/Pages/Checkout';

import InvoicePrint from './component/MyAccount/InvoicePrint';
import DocuSignConsent from './component/Pages/DocuSignConsent';
import Categories from './component/Products/Categories';
import NotFoundPage from './component/Pages/NotFoundPage';
import ErrorPage from './component/Pages/ErrorPage';
import UnsubscribePage from './component/Pages/UnsubscribePage';

export default function App() {
  
  const location=useLocation();
  let lastUrlValue = location.pathname.slice(location.pathname.lastIndexOf("/") , location.pathname.length);

  const user = JSON.parse(localStorage.getItem('user'));
  const navigate=useNavigate();
  const token = localStorage.getItem('') 
  const isRegsuccessPanelRendering = location.pathname==="/registration-success"
  const isInvoiceDetailsRendering = location.pathname==="/invoice-details"+lastUrlValue

  const genderData = useSelector(state=>state?.category?.gender)
    
      useEffect(()=>{
      if(genderData?.length>0){
        }
      },[])
  return (
    <>

        <div className="page-wrapper">
   
            {!isRegsuccessPanelRendering  && !isInvoiceDetailsRendering && <Header/>}
  
          <Routes>
            <Route path="/invoice-details/:id" element={<InvoicePrint/>}></Route>
            <Route path="/accept-consent" element={<DocuSignConsent/>}></Route> 

            <Route path="/" element={<Home/>}></Route>  
            <Route path="/about-us" element={<Aboutus/>}></Route> 
            <Route path="/contact-us" element={<Contactus/>}></Route>
            <Route path="/my-account" element={user?.token ? <Myaccount/> : <Navigate to="/login" replace={true} /> } />
            <Route path="/products" element={<Products/>}></Route>
            <Route path="/products/:id" element={<Products/>}></Route>
            <Route path="/product-details/:id" element={<Productdetails/>}></Route>
            <Route path="/registration-success" element={<Regsucceess/>}></Route>
            <Route path="/login" element={<Signin/>}></Route>
            <Route path="/categories" element={<Categories />} />
            <Route path="/register" element={<Register/>}></Route>
    
            <Route path="/forgetpass" element={<ForgetPass/>}></Route>
            <Route path="/reset-password/:id" element={<ResetPassword/>}></Route>
            <Route path="/cart" element={ <Cart/> }></Route>
            <Route path="/cart-checkout" element={ <Checkout/> }></Route>
            <Route path="/wishlist" element={user?.token ? <Wishlist/> : <Navigate to="/login" replace={true} /> }></Route>
            <Route path="/payment-success" element={<PaymentSuccess />}></Route>
            <Route path="/order-details/:id" element={user?.token ? <OrderDetails/> : <Navigate to="/login" replace={true} /> }></Route>
            <Route path="/terms-and-conditions" element={<TermAndCond />} />

            <Route path="/error/:pathParam?" element={<ErrorPage/>}></Route> 
            <Route path="*" element={<NotFoundPage/>}></Route> 
            <Route path="/unsubscribe/:id/:type" element={<UnsubscribePage/>}></Route> 

          </Routes>
          {!isInvoiceDetailsRendering && <Footer/>}
        </div>
    </>
  )
}