import React, { useState, useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Loader from './common/Loader';
import { Link } from "react-router-dom"; 
import axiosIntance from '../helper/axios';
import Config from '../config.json';

export default function Home() {

  const userData = JSON.parse(localStorage.getItem('user'));
  let applicableDiscount = JSON.parse(localStorage.getItem("userType"))?.applicableDiscount;

  const [bannardata, setBannardata] = useState('');
  const [loader, setLoader] = useState(false);
  const [accessoryProduct, setAccessoryProduct] = useState([]);
  const [sliderState, setSliderState] = useState(false);
  
  useEffect(() => {
    fetchBannarList();
    fetchProducts();
  }, []);
  
  const redirect = () => {
    window.location.href = "/register";
  }
  const fetchProducts = (e) => {
    setLoader(true);
    const url = process.env.REACT_APP_BASE_URL + 'get-featured-products';
    axiosIntance.get(url).then(response => {
      localStorage.setItem('accessory',response?.data?.body.accessoryProducts)
      setAccessoryProduct(response?.data?.body?.accessoryProducts);
      setSliderState(true)
      setLoader(false);
    })
  }
  
  const fetchBannarList = async () => {
    const url = process.env.REACT_APP_BASE_URL + 'get-banners';
    axiosIntance.get(url).then(res => {
      setBannardata(res.data);
    })
  }


  return (
  <>
    <main className="main">
      <div className='owl-theme mobile-view edit-owl'>
        {
          bannardata ? bannardata?.body?.bannerSection1?.map((data, i) => {
            let prdLnk = data?.productLink != "undefined" ? data?.productLink : "javascript:void(0)"
            return (
              data?.status == 1 && (
                <Link href={prdLnk} >
                  <div className="home-slide home-slide1 banner">
                    <img src={data?.image} alt='' />
                    <div className='cards banner-1'>
                      <p>Groothandel In</p>
                   
                      <p>Consumenten</p>
                      
                      <p>Elektronica</p>
                  </div>
                     
                  </div>
                </Link>
              )
            )
          }) : null
        }
      </div>
      <section>
        <div className="outer-wrapper owl-theme mobile-view edit-owl ">
          <div className="inner-wrapper">
            {
              bannardata ? bannardata?.body?.bannerSection2?.map((data, i) => {
                let prdLnk = data?.productLink != "undefined" ? data?.productLink : "javascript:void(0)"
                return (
                  data?.status == 1 && (
                    <Link href={prdLnk} >
                      <div className=" sec-towoftow pseudo-item">
                        <img src={data?.image} alt='' />
                        { data?.title ?
                        <div className='cards banner-3'>
                          <p>{data?.title}</p>
                        </div>
                        :"" }
                      </div>
                    </Link>
                  )
                )
              }) : null
            }
          </div>
        </div>
        <div className="pseduo-track"></div>
      </section>
  <Loader loading={loader} />
{/* main banner */}
    <div className="container-fluid ">
      <div className="row bor-der homebanner ">
        {
          bannardata?.body?.bannerSection1?.map((banner, i) => {
            let prdLnk = banner?.productLink != "undefined" ? banner?.productLink : "javascript:void(0)"
            return(
              <>
              {
                banner?.status == 1 && (
                  <div className="col sec-one col-xs-12 col-md-12 col-sm-12">
                    <Link href={prdLnk} >
                      <img src={banner?.image} alt='' />
                      <div className='cards banner-1'>
                        <p>Groothandel In</p>
                        <p>Consumenten</p>
                        <p>Elektronica</p>
                      </div>
                    </Link>
                  </div>
                )
              }
              </>
            )
          })
        }
      </div>
{/* mini-banner */}
      <div className="row bor-der-2 min-desktop homebanner">
        {
          bannardata?.body?.bannerSection2?.map((miniBannar, i) => {
            let prdLnk = miniBannar?.productLink != "undefined" ? miniBannar?.productLink : "javascript:void(0)"
            return(
              <>
              {
                miniBannar?.status == 1 && (
                <div className="sec-towoftow col-xs-12 col-md-4 col-sm-12">
                  <Link to={ userData != null ? '' : prdLnk} >
                    <img src={miniBannar?.image} alt='' />
                    { miniBannar?.title ? 
                    <div className='cards banner-3'>
                      <p>{ miniBannar?.title }</p>
                    </div>
                    : '' }
                  </Link>
                </div>
                )
              }
              </>
            )
          })
        }
        
      </div>
    </div>
    <div className="home-product-tabs product-slider-tab pt-5 pt-md-0 pb-5">
      <div className="container-fluid">
        <div className='row accessries'>
          { sliderState && (
        <OwlCarousel className='owl-theme edit-owl' loop={true} items={4} dots={false} autoplay={true} autoplayTimeout={2000}>
        { accessoryProduct ?
            accessoryProduct?.map(product => {
              return(
                  <div className="product-default inner-quickview inner-icon quantity-input col-3 card mt-1 item">
                      <figure>
                          <Link to={`/product-details/${product.slug}`}>
                              <img src={product?.images[0]} width="280" height="280" alt="product"/>
                          </Link>
                          <Link to={`/product-details/${product.slug}`} className="btn-quickview" title="Bekijken">bekijken</Link>
                      </figure>
                      <div className="product-details">
                          <div className="category-list">
                              <a href="demo14-shop.html" className="product-category">{product?.brand}</a>
                          </div>
                          <h3 className="product-title"> <Link to={`/product-details/${product.slug}`}>{product?.name}</Link> </h3>
                          { userData ? 
                          <div className="price-box">
                              <span className="product-price">
                              {
                                applicableDiscount ?
                                (process.env.REACT_APP_CURRENCY + (Number(parseFloat(product?.salePrice['$numberDecimal']) + ( (applicableDiscount/100) * parseFloat(product?.salePrice['$numberDecimal'] ))).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                                :
                                process.env.REACT_APP_CURRENCY+Number(parseFloat(product?.salePrice['$numberDecimal']))
                              }
                              </span>
                          </div>
                          : '' }
                      </div>
                  </div>
              )}) : null}
          </OwlCarousel>
          )}
        </div>
      </div>
    </div>
    <div className="feature-boxes-container pt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-lg-4" >
            <div className="feature-box mx-md-3 feature-box-simple text-center bg-offwhite box-feature" onClick={redirect}>
              <i className="icon-earphones-alt" />
              <hr/>
              <div className="feature-box-content">
                <h3>Word klant bij ons</h3>
                <ul>
                  <li>Plaats online bestellingen</li>
                  <li>Profiteer van acties en kortingen</li>
                  <li>Bekijk direct de voorraad</li>
                  <li>Ontvang ons magazine</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="feature-box mx-md-3 feature-box-simple text-center bg-offwhite box-feature">
              <i className="icon-credit-card" />
              <hr/>
              <div className="feature-box-content">
                <h3>Vraag informatie / advies aan</h3>
                <ul>
                  <li>Vraag een offerte aan</li>
                  <li>Advies bij technische problemen</li>
                  <li>Nog een vraag?</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 ">
            <div className="feature-box mx-md-3 feature-box-simple text-center bg-offwhite box-feature">
              <i className="icon-action-undo" />
              <hr/>
              <div className="feature-box-content">
                <h3>nloggen / bestellen</h3>
                <ul>
                  <li>Direct bestellen</li>
                  <li>Controleer de realtime voorraad</li>
                  <li>Bekijk acties</li>
                  <li>Vraag informatie aan</li>
                  <li>Bekijk bestelstatus</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

        {/* <div className='container-fluid pb-5 b2bplatform-content border-bottom'>
          <div className='row mt-3'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <h1>Sluit u aan bij het grootste B2B-platform van Nederland</h1>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-12 p-5'>
              <div className="count-wrapper">
                <span className="count-to" >60</span>+
              </div>
                <h4 className="count-title">MILJOEN KLANTEN</h4>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-12 p-5'>
              <div className="count-wrapper">
                <span className="count-to" >10,000</span>+
              </div>
                <h4 className="count-title">TOTAAL PRODUCTEN</h4>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-12 p-5'>
              <div className="count-wrapper">
                <span className="count-to" >24*7</span>
              </div>
                <h4 className="count-title">ONDERSTEUNING BESCHIKBAAR</h4>
            </div>

            <div className='col-lg-12 col-md-12 col-sm-12 b2bplatform-btn btn pb-5 pt-5'>
                <Link className="btn-member p-3" style={{color:"#308B7B"}} to='/login'>Om klant te worden</Link>
            </div>

          </div>
        </div> */}

        {/* <div className='container-fluid brand-content border-bottom'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 center'>
              <h1>Topmerken in alle categorieën</h1>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 pb-5 ml-5 brand-lists'>
              <ul className='brand-ul ml-5'>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/celly.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/jbl.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/philips.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/apple.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/bose.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/fossil.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/nokia.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/norton.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/samsung.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/google.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/adidas.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/lg.png`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/sony.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/xiaomi.jpg`}/>
                  </li>
                  <li>
                    <img src={Config.REACT_BASE_URL+`/custom/assets/images/brands/oppo.jpg`}/>
                  </li>
              </ul>
            </div>
          </div>
        </div> */}

    <div className='container-fluid container-not-boxed' style={{paddingLeft:"0px",paddingRight:"0px"}}>
      <div className="info-boxes-slider brand-content">
        <div className='row p-5'>
          <div className='col-lg-3 col-xs-12 col-sm-6'>
            <div className="info-box info-box-icon-left ">
                <i className="icon-shipping font-35 pt-1"></i>

                <div className="info-box-content">
                    <h4>GRATIS VERZENDING & RETOURNEREN</h4>
                    <p className="text-body">Naar Nederland</p>
                </div>
            </div>
          </div>
          <div className='col-lg-3 col-xs-12 col-sm-6'>
            <div className="info-box info-box-icon-left">
                <i className="icon-money"></i>
                <div className="info-box-content">
                    <h4>BEVEILIGD BETALEN</h4>
                    <p className="text-body">Veilige betaalopties</p>
                </div>
            </div>
          </div>
          <div className='col-lg-3 col-xs-12 col-sm-6'>
            <div className="info-box info-box-icon-left">
                <i className="icon-support"></i>
                <div className="info-box-content">
                    <h4>KLANTENSERVICE 24/7</h4>
                    <p className="text-body">Beschikbaar per telefoon en e-mail op werkdagen</p>
                </div>
            </div>
          </div>
          <div className='col-lg-3 col-xs-12 col-sm-6'>
            <div className="info-box info-box-icon-left">
                <i className="icon-cart-thick"></i>
                <div className="info-box-content">
                    <h4>Garantie</h4>
                    <p className="text-body">14 dagen bedenktijd</p>
                </div>
            </div>
          </div>
        </div>
        </div>
    </div>
    </main>
  </>
  )
}
