import axios from "axios";
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react";
import Config from "../../config.json";
import { emptyCart, getUserCart} from "../../action/cartActions"; 
import axiosIntance from "../../helper/axios";
import Loader from '../common/Loader';
import { removeLocalStorage, removeCookies } from "../common/utils.js"

const PaymentSuccess = () => {

    let orderId, referenceId, totalPaidAmount, paymentMode, oStatus;

    const queryParams = new URLSearchParams(window.location.search); 
    const orderStatusId = queryParams.get('orderStatusId')
    let paymentId = queryParams.get('orderId')

    const userId = localStorage.getItem("userId");
    var allcookies = document.cookie;
    let cookiearray = allcookies.split(';');

    const[responsedata, setResponsedata]= useState('');
    const[orderStatus, setOrderStatus] = useState(orderStatusId);
    const userCartState = useSelector(state => state?.getUserCart);

    const dispatch = useDispatch();  
    const updatedCart = useSelector(state => state?.removedFromCart);
    const emptyCartState = useSelector(state => state?.emptyCart);
    const [loader, setLoader] = useState(false);

    for (var i = 0; i < cookiearray.length; i++) {
        let name = cookiearray[i].split('=')[0];
        let value = cookiearray[i].split('=')[1];
        if (name == "orderId" || name == " orderId") orderId = value;
        if (name == "referenceId" || name == " referenceId") referenceId = value;
        // if (name == "paymentId" || name == " paymentId") paymentId = value;
        if (name == "totalPaidAmount" || name == " totalPaidAmount") totalPaidAmount = value;
        if (name == "paymentMode" || name == " paymentMode") paymentMode = value;
    }


    const createPayment = async () => {

        setLoader(true)
        if(orderStatus== 100){ // payment success
            oStatus = 1;
        }
        else if(orderStatus== -90){ // payment cancel
            oStatus = 2
        }
        else if(orderStatus== -63){ // payment declined
            oStatus = 3
        }
        await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}create-payment`, {
            userId,
            orderId,
            referenceId,
            paymentId,
            totalPaidAmount,
            paymentMode: paymentMode, //1 - online, 2 - cash
            paymentStatus: (paymentMode==1 ? oStatus : 0) // 1 = completed, 2 = cancelled, 3 = declined
        }).then( response => {
            removeLocalStorage()
            setLoader(false);

            if(response?.data?.code== 400){
                setOrderStatus(400)
            }
            else if(response?.data?.body?.errorId== -1){
                setOrderStatus(response?.data?.body?.errorId)
                setResponsedata(response?.data?.body?.errorMessage);
            }else if(response?.data?.body?.errorId == -2){
                setOrderStatus(response?.data?.body?.errorId)
                setResponsedata(response?.data?.body?.errorMessage);
            }
            else if(response?.data?.body?.errorId== 101){
                setOrderStatus(response?.data?.body?.errorId)
                setResponsedata(response?.data?.body?.referenceId);
            }
            else{
                setResponsedata(response?.data?.body?.referenceId);
            }          
        }).catch(err =>{});

    }

    const emptyCartHandler = () => {
        dispatch(emptyCart());
    } 

    useEffect(() => { 
        dispatch(getUserCart());
    }, [updatedCart, emptyCartState]);


    useEffect(() => {
        setTimeout(() => {
            createPayment();
        }, 2000);
        emptyCartHandler()
         localStorage.removeItem("userCart");  

    }, []);

    return (
    <>
    <Loader loading={loader} />
    {
        (()=>{
            if( orderStatus == -1 )
            {
                return (
                    <div className="container ">
                        <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
                                    <li className="disabled">
                                        <a href="#">Winkelwagen</a>
                                    </li>
                                    <li className="disabled">
                                        <a href="#">Afrekenen</a>
                                    </li>
                                    <li className="active">
                                        <a href="#">Bevestiging</a>
                                    </li>
                                </ul>
                        <div className="row ordersuccess ">
                            <div className="col-10 mx-auto card-ordersuccess">
                                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/cancel.png`} alt=''/>
                                <h3>{ responsedata }</h3>
                            </div>
                        </div>
                    </div>
                )
            }
            if( orderStatus == -2 )
            {
                return (
                    <div className="container ">
                        <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
                                    <li className="disabled">
                                        <a href="#">Winkelwagen</a>
                                    </li>
                                    <li className="disabled">
                                        <a href="#">Afrekenen</a>
                                    </li>
                                    <li className="active">
                                        <a href="#">Bevestiging</a>
                                    </li>
                                </ul>
                        <div className="row ordersuccess ">
                            <div className="col-10 mx-auto card-ordersuccess">
                                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/cancel.png`} alt=''/>
                                <h3>{ responsedata }</h3>
                            </div>
                        </div>
                    </div>
                )
            }
            else if( orderStatus == 400){
                return (
                    <div className="container ">
                        <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
                                    <li className="disabled">
                                        <a href="#">Winkelwagen</a>
                                    </li>
                                    <li className="disabled">
                                        <a href="#">Afrekenen</a>
                                    </li>
                                    <li className="active">
                                        <a href="#">Bevestiging</a>
                                    </li>
                                </ul>
                        <div className="row ordersuccess ">
                            <div className="col-10 mx-auto card-ordersuccess">
                                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/check-orderplaced.png`} alt=''/>
                                <h3>Bestelling is al aangemaakt.</h3>
                            </div>
                        </div>
                    </div>
                )
            }
            else if( orderStatus == 100 || orderStatus == 101){
                return (
                    <div className="container ">
                        <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
                                    <li className="disabled">
                                        <a href="#">Winkelwagen</a>
                                    </li>
                                    <li className="disabled">
                                        <a href="#">Afrekenen</a>
                                    </li>
                                    <li className="active">
                                        <a href="#">Bevestiging</a>
                                    </li>
                                </ul>
                        <div className="row ordersuccess ">
                            <div className="col-10 mx-auto card-ordersuccess">
                                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/check-orderplaced.png`} alt=''/>
                                <h3>Bestelling succesvol geplaatst</h3>
                                <div>
                                    <h4>Ordernummer: <span>{responsedata}</span></h4>
                                    <p></p>
                                </div>
                                <div className="order-message">
                                    <p>Dankjewel voor je bestelling!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else if( orderStatus == -90 || orderStatus == -63 ){
                return (
                    <div className="container ">
                        <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
                            <li className="disabled">
                                <a href="#">Winkelwagen</a>
                            </li>
                            <li className="disabled">
                                <a href="#">Afrekenen</a>
                            </li>
                            <li className="active">
                                <a href="#">Bevestiging</a>
                            </li>
                        </ul>
                        <div className="row ordersuccess ">
                            <div className="col-10 mx-auto card-ordersuccess">
                                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/cancel.png`} alt=''/>
                                <h3>Bestelling is geannuleerd of geweigerd!</h3>
                                <div>
                                    <h4>Ordernummer: <span>{responsedata}</span></h4>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })
    ()}
        
</>
    )
}

export default PaymentSuccess;