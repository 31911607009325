import React, {useEffect, useState} from 'react';
import Config from "../../config.json"
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from '../common/Loader';

export default function Unsubscribe() {
    const params = useParams();
    const [loader, setLoader] = useState(false);

    useEffect(()=>{
        unsubscribeFunction();
    },[]);

    const unsubscribeFunction = async ()=>{
        setLoader(true)
        let obj ={
            userId : params.id,
            type: params.type
        }
        await axios.post(`${process.env.REACT_APP_BASE_URL}unsubscribe`, {reqData:obj}).then(res=>{
            if(res.data.success == 1){
                setLoader(false)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    return (
        <>
            <Loader loading={loader} />
            <div className="container ">
                        <div className="row ordersuccess ">
                            <div className="col-10 mx-auto card-ordersuccess">
                                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/check-orderplaced.png`} alt=''/>
                                <p className="error-explanation text-center">
                                    je bent afgemeld
                                </p>
                                <span>je ontvangt geen e-mails meer van techbyte.nl</span>
                            </div>
                        </div>
                </div>
        </>
    )
}