import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios"
import { useState } from "react";
import { useEffect } from "react";
import dummyImage from "../../assets/images/placeholder-img-150x150.png";
import dummyImage1 from "../../assets/images/placeholder-img-100x100.png";
import { addToCartAction, getUserCart } from '../../action/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const ProductsByColor = () => {

    const user = localStorage.getItem("user");
    const location = useLocation();
    const params = useParams();
    const [productList, setProductList] = useState();
    const [categoryList, setCategoryList] = useState(null);
    const [showProducts, setShowProducts] = useState(12);//11
    const [pageNo, setPageNo] = useState(1);
    const [sortList, setSortList] = useState(null);
    const [listType, setListType] = useState("grid");
    const navigate = useNavigate();
    const [productNumber, setProductNumber] = useState();
    const [prodQty, setProdQty] = useState(1);
    const updatedCart = useSelector(state => state?.addedToCart);
    const dispatch = useDispatch();
    const [prodId, setProdId] = useState("");
    
 

    const fetchProductListByCategory = async () => {
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}get-products`, {
            color: params.id,
            category:params.cid,//location?.state?.category
            page: pageNo,
            limit: showProducts,
            sortFlag: sortList
        }); 
        setProductList(data);
        setProductNumber(data?.body?.totalProducts);
    }
    const fetchCategoryList = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}get-categories`);  
        setCategoryList(data);
    }

    useEffect(() => {
        fetchProductListByCategory();
    }, [params, pageNo, showProducts, listType, sortList]);

    useEffect(() => {
        fetchCategoryList();
    }, []);

    useEffect(() => {
        dispatch(getUserCart());
      }, [updatedCart]);

    const categoryListHandler = (slug, id) => {
        navigate(`/products-by-category/${id}`, {
          state: {
            category: id
          }
        })
      }
      const brandListHandler = (id) => {
        navigate(`/products-by-brand/${id}`, {
          state: {
            brand: id
          }
        })
      }
      const colorListHandler = (id) => {
        navigate(`/products-by-color/${id}`, {
          state: {
            color: id
          }
        })
      }
      const addToCartHandler = (totalPrice, shippingDetails, item, totalQuantity) => {
        dispatch(addToCartAction({ totalPrice, shippingDetails, item, totalQuantity }));
      }
      const uniqueTags = [];
      productList?.body?.prodcuts?.map((item) => {
        var findItem = uniqueTags.find((x) => x.brand === item.brand);
        if (!findItem) uniqueTags.push(item);
      });
      const uniqueColor = [];
        productList?.body?.prodcuts?.map((itemc) => {
            itemc.colors.map((itemcc)=>{
            var findItem = uniqueColor.find((x) => x.attributeValue === itemcc.attributeValue);
            if (!findItem) uniqueColor.push(itemcc);
            });    
        });
    return (
        <>
            <main className="main caty-page">
                {/* parallax */}
                <div className="promo-section bg-gray" data-parallax="{'speed': 2, 'enableOnMobile': true}" data-image-src="assets/images/demoes/demo14/banners/promo-bg.png">
                    <div className="promo-banner banner container text-uppercase">
                        <div className="banner-content row align-items-center text-center">
                            <div className="col-md-5 col-lg-4 ml-xl-auto text-md-right">
                                <h2 className="mb-md-0">Top electronic
                                    <br />Deals</h2>
                            </div>
                            <div className="col-md-3 pb-4 pb-md-0">
                                <a href="#" className="btn btn-primary ls-10">View Sale</a>
                            </div>
                            <div className="col-md-4 mr-xl-auto text-md-left">
                                <h4 className="mb-1 coupon-sale-text p-0 d-block ls-10 text-transform-none">
                                    <b className="bg-dark text-white font1">Exclusive COUPON</b>
                                </h4>
                                <h5 className="mb-2 coupon-sale-text ls-10 p-0">
                                    <i className="ls-0">UP TO</i>
                                    <b className="text-white bg-secondary"> {process.env.REACT_APP_CURRENCY}100</b> OFF</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="demo14.html">
                                    <i className="icon-home" />
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">shop</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-9 main-content">
                            <nav className="toolbox sticky-header" data-sticky-options="{'mobile': true}">
                                <div className="toolbox-left">
                                    <a href="#" className="sidebar-toggle">
                                        <svg data-name="Layer 3" id="Layer_3" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <line x1={15} x2={26} y1={9} y2={9} className="cls-1" />
                                            <line x1={6} x2={9} y1={9} y2={9} className="cls-1" />
                                            <line x1={23} x2={26} y1={16} y2={16} className="cls-1" />
                                            <line x1={6} x2={17} y1={16} y2={16} className="cls-1" />
                                            <line x1={17} x2={26} y1={23} y2={23} className="cls-1" />
                                            <line x1={6} x2={11} y1={23} y2={23} className="cls-1" />
                                            <path d="M14.5,8.92A2.6,2.6,0,0,1,12,11.5,2.6,2.6,0,0,1,9.5,8.92a2.5,2.5,0,0,1,5,0Z" className="cls-2" />
                                            <path d="M22.5,15.92a2.5,2.5,0,1,1-5,0,2.5,2.5,0,0,1,5,0Z" className="cls-2" />
                                            <path d="M21,16a1,1,0,1,1-2,0,1,1,0,0,1,2,0Z" className="cls-3" />
                                            <path d="M16.5,22.92A2.6,2.6,0,0,1,14,25.5a2.6,2.6,0,0,1-2.5-2.58,2.5,2.5,0,0,1,5,0Z" className="cls-2" />
                                        </svg>
                                        <span>Filter</span>
                                    </a>
                                    <div className="toolbox-item toolbox-sort">
                                        <label>Sort By:</label>
                                        <div className="select-custom">
                                            <select name="orderby" className="form-control"
                                            // value={sortList}
                                            onClick={(e) => setSortList(e.target.value)}>
                                                {/* <option value="popularity">Sort by popularity</option>
                                                <option value="rating">Sort by average rating</option>
                                                <option value="date">Sort by newness</option> */}
                                                <option value="">Select Option</option>
                                                <option value={-1}>Sort by price: low to high</option>
                                                <option value={1}>Sort by price: high to low</option>
                                            </select>
                                        </div>
                                        {/* End .select-custom */}
                                    </div>
                                    {/* End .toolbox-item */}
                                </div>
                                {/* End .toolbox-left */}
                                <div className="toolbox-right">
                                    <div className="toolbox-item toolbox-show">
                                        <label>Show:</label>
                                        <div className="select-custom">
                                            <select name="count" className="form-control" value={showProducts} onChange={(e) => setShowProducts(e.target.value)}>
                                                <option value={12}>12</option>
                                                <option value={24}>24</option>
                                                <option value={36}>36</option>
                                            </select>
                                        </div>
                                        {/* End .select-custom */}
                                    </div>
                                    {/* End .toolbox-item */}
                                    <div className="toolbox-item layout-modes">
                                        <a href="#" className="layout-btn btn-grid active" title="Grid" onClick={() => setListType("grid")}>
                                            <i className="icon-mode-grid" />
                                        </a>
                                        <a href="#" className="layout-btn btn-list" title="List" onClick={() => setListType("list")} >
                                            <i className="icon-mode-list" />
                                        </a>
                                    </div>
                                    {/* End .layout-modes */}
                                </div>
                                {/* End .toolbox-right */}
                            </nav>
                            {
                                listType == "grid" ? (
                                    <div className="row products-group products-caty">
                                        {
                                            productList ? productList?.body?.prodcuts?.map((list, id) => {
                                                return (
                                                    <div className="col-md-4 col-6">
                                                        <div className="product-default inner-quickview inner-icon quantity-input">
                                                            <figure>
                                                                <Link to={`/product-details/${list.slug}`}>
                                                                    <img src={list.images.length != 0 ? list.images[0] : dummyImage} width={280} height={280} alt="product" />
                                                                </Link>
                                                                {/* <div className="label-group">
                            <span className="product-label label-hot">HOT</span>
                            <span className="product-label label-sale">-30%</span>
                          </div> */}
                                                                <div className="btn-icon-group">
                                                                    <a href="#" className="btn-icon btn-icon-wish">
                                                                        <i className="icon-heart" />
                                                                    </a>
                                                                </div>
                                                                <a href="ajax/product-quick-view.html" className="btn-quickview" title="Quick View">Quick View</a>
                                                            </figure>
                                                            <div className="product-details">
                                                                <div className="category-list">
                                                                    <a href="demo14-shop.html" className="product-category">{list.brand}</a>
                                                                </div>
                                                                <h3 className="product-title"> <a href="demo14-product.html">{list.name}</a>
                                                                </h3>
                                                                <div className="ratings-container">
                                                                    <div className="product-ratings">
                                                                        <span className="ratings" style={{ width: '100%' }} />
                                                                        {/* End .ratings */}
                                                                        <span className="tooltiptext tooltip-top" />
                                                                    </div>
                                                                    {/* End .product-ratings */}
                                                                </div>
                                                                {/* End .product-container */}
                                                                <div className="price-box">
                                                                    <span className="product-price">{user ? (process.env.REACT_APP_CURRENCY + list.regularPrice['$numberDecimal']) : ""}</span>
                                                                </div>
                                                                {/* End .price-box */}
                                                                <div className="product-action">
                                                                    <div className="product-single-qty">
                                                                        {/* <input className="horizontal-quantity form-control" type="text" /> */}
                                                                        <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                                                            <span class="input-group-btn input-group-prepend">
                                                                            <button class="btn btn-outline btn-down-icon bootstrap-touchspin-down" type="button"></button>
                                                                            </span>
                                                                            {/* <input class="horizontal-quantity form-control" type="text" /> */}
                                                                            <input className="horizontal-quantity form-control" type="text" value={prodQty} onChange={(e) => setProdQty(e.target.value)} />
                                                                            <span class="input-group-btn input-group-append">
                                                                            <button class="btn btn-outline btn-up-icon bootstrap-touchspin-up" type="button"></button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    {/* End .product-single-qty */}
                                                                    {/* <button className="btn-icon btn-add-cart" data-toggle="modal" data-target="#addCartModal">
                                                                        <i className="icon-shopping-cart" />ADD TO CART</button> */}
                                                                    <a href="#"
                                                                    onClick={(e) => {
                                                                        let totalPrice = (Number(list?.regularPrice['$numberDecimal']) * Number(prodQty)).toString();
                                                                        let shippingDetails = {};
                                                                        let totalQuantity = prodQty; 
                                                                        let item = {
                                                                        id: uuidv4(),
                                                                        itemId: prodId,
                                                                        product: list?.name,
                                                                        quantity: prodQty,
                                                                        price: list?.regularPrice['$numberDecimal'],
                                                                        subTotal: totalPrice,
                                                                        size: "",
                                                                        imgPath: list?.images?.[0]
                                                                        } 
                                                                        addToCartHandler(totalPrice, shippingDetails, item, totalQuantity)
                                                                    }}
                                                                    className="btn btn-dark add-cart mr-2 btn-icon btn-add-cart" title="Add to Cart">
                                                                        <i className="icon-shopping-cart" />Add to Cart
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            {/* End .product-details */}
                                                        </div>
                                                    </div>
                                                )
                                            }) : null
                                        }
                                    </div>
                                ) : listType == "list" ? (
                                    <div class="row pb-4">

                                        {
                                            productList ? productList?.body?.prodcuts?.map((list, id) => {
                                                return (
                                                    <div class="col-sm-12 col-6 product-default left-details product-list mb-2">
                                                        <figure>
                                                            <Link to={`/product-details/${list.slug}`}>
                                                                <img src={list.images.length != 0 ? list.images[0] : dummyImage} width="250" height="250"
                                                                    alt="product" />
                                                                <img src={list.images.length != 0 ? list?.images[1] : dummyImage} width="250" height="250"
                                                                    alt="product" />
                                                            </Link>
                                                        </figure>
                                                        <div class="product-details">
                                                            <div class="category-list">
                                                                <a href="#" class="product-category">category</a>
                                                            </div>
                                                            <h3 class="product-title"> <Link to={`/product-details/${list.slug}`}>{list.name}</Link>
                                                            </h3>
                                                            <div class="ratings-container">
                                                                <div class="product-ratings">
                                                                    <span class="ratings" style={{ width: '100%' }}></span>
                                                                    {/* <!-- End .ratings --> */}
                                                                    <span class="tooltiptext tooltip-top"></span>
                                                                </div>
                                                                {/* <!-- End .product-ratings --> */}
                                                            </div>
                                                            {/* <!-- End .product-container --> */}
                                                            <p class="product-description">{list.shortDescription}</p>
                                                            <div class="price-box">
                                                                <span class="product-price">{user ? (process.env.REACT_APP_CURRENCY + list.regularPrice['$numberDecimal']) : ""}</span>
                                                            </div>
                                                            {/* <!-- End .price-box --> */}
                                                            <div class="product-action">
                                                                <a href="#" class="btn-icon btn-add-cart product-type-simple">
                                                                    <i class="icon-shopping-cart"></i>
                                                                    <span>ADD TO CART</span>
                                                                </a>
                                                                <a href="wishlist.html" class="btn-icon-wish" title="wishlist">
                                                                    <i class="icon-heart"></i>
                                                                </a>
                                                                <a href="ajax/product-quick-view.html" class="btn-quickview" title="Quick View">
                                                                    <i class="fas fa-external-link-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        {/* <!-- End .product-details --> */}
                                                    </div>
                                                )
                                            }) : null
                                        }

                                    </div>
                                ) : null
                            }
                            {/* End .row */}


                            {productNumber > showProducts ? 
                            <nav className="toolbox toolbox-pagination">
                                {/* <div className="toolbox-item toolbox-show">
                                    <label>Show:</label>
                                    <div className="select-custom">
                                        <select name="count" className="form-control">
                                            <option value={12}>12</option>
                                            <option value={24}>24</option>
                                            <option value={36}>36</option>
                                        </select>
                                    </div>                                    
                                </div> */}
                                {/* End .toolbox-item */}
                                <ul className="pagination toolbox-item">
                                    <li className="page-item disabled">
                                        <a className="page-link page-link-btn" href="#">
                                            <i className="icon-angle-left" />
                                        </a>
                                    </li>
                                    <li className="page-item active">
                                        <a className="page-link" href="#" onClick={() => { 
                                            setPageNo(1);
                                        }}>1
                                            <span className="sr-only">(current)</span>
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => { 
                                            setPageNo(2);
                                        }}>2</a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => { 
                                            setPageNo(3);
                                        }}>3</a>
                                    </li>
                                    <li className="page-item">
                                        <span className="page-link">...</span>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link page-link-btn" href="#">
                                            <i className="icon-angle-right" />
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            : null }
                        </div>


                        {/* End .col-lg-9 */}
                        <div className="sidebar-overlay" />
                        <aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
                            <div className="sidebar-wrapper">
                                <div className="widget">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="false" aria-controls="widget-body-2">Categories</a>
                                    </h3>
                                    <div className="collapse show" id="widget-body-2">
                                        <div className="widget-body">
                                            <ul className="cat-list">
                                                {
                                                    categoryList ? categoryList?.body?.map((list, i) => {
                                                        return (
                                                            // need to work on design for dropdown
                                                            <li key={i} >
                                                                <a href={`#widget-category-${i + 1}`} className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls={`widget-category-${i + 1}`}>
                                                                    {list.name}
                                                                    <span className="products-count">({list.subCategory.length})</span>
                                                                    <span className="toggle" />
                                                                </a>
                                                                <div className="collapse" id={`widget-category-${i + 1}`}>
                                                                    <ul className="cat-sublist">
                                                                        {/* {
                                                                            list ? list?.subCategory?.map?.((subList, i) => {
                                                                                <li key={i} >{subList.name}
                                                                                    <span className="products-count">(1)</span>
                                                                                </li>
                                                                            }) : null
                                                                        } */}
                                                                        {
                                                                            list?.subCategory?.map((subcat, i) => (
                                                                                <li href="#" onClick={() => categoryListHandler(subcat.slug, subcat._id)}>{subcat.name}</li>
                                                                            )
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        )
                                                    }) : null
                                                }
                                            </ul>
                                        </div>
                                        {/* End .widget-body */}
                                    </div>
                                    {/* End .collapse */}
                                </div>
                                {/* End .widget */}
                                {/* <div className="widget">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Price</a>
                                    </h3>
                                    <div className="collapse show" id="widget-body-3">
                                        <div className="widget-body pb-0">
                                            <form action="#">
                                                <div className="price-slider-wrapper">
                                                    <div id="price-slider" />
                                                    
                                                </div>
                                                
                                                <div className="filter-price-action d-flex align-items-center justify-content-between flex-wrap">
                                                    <div className="filter-price-text">
                                                        Price:
                                                        <span id="filter-price-range" />
                                                    </div>
                                                    
                                                    <button type="submit" className="btn btn-primary">Filter</button>
                                                </div>
                                                
                                            </form>
                                        </div>
                                        
                                    </div>
                                    
                                </div> */}
                                {/* End .widget */}
                                <div className="widget widget-size">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-5" role="button" aria-expanded="true" aria-controls="widget-body-5">Brand</a>
                                    </h3>
                                    <div className="collapse show" id="widget-body-5">
                                        <div className="widget-body pb-0">
                                        <ul className=" cat-list">
                                        { uniqueTags.map((list, id) => {
                                            return (
                                                    <li className="active">
                                                        <a href="#" onClick={() => brandListHandler(list.brand)}>{list.brand}</a>
                                                    </li>
                                                )
                                                })
                                            }
                                            </ul>
                                        </div>
                                        {/* End .widget-body */}
                                    </div>
                                    {/* End .collapse */}
                                </div>
                                {/* End .widget */}

                                <div className="widget widget-size">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Color</a>
                                    </h3>
                                    <div className="collapse show" id="widget-body-3">
                                        <div className="widget-body pb-0">
                                        <ul className=" cat-list">
                                        { uniqueColor.map((listcolor, id) => {
                                          return (
                                                <li className="active">
                                                    <a href="#" onClick={() => colorListHandler(listcolor.attributeValue)}>{listcolor.attributeValue}</a>
                                                </li>
                                              )
                                            })
                                          }
                                        </ul>
                                        </div>
                                        {/* End .widget-body */}
                                    </div>
                                    {/* End .collapse */}
                                </div>


                                {/* <div className="widget widget-brand">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-5" role="button" aria-expanded="false" aria-controls="widget-body-6">Brand</a>
                                    </h3>
                                    <div className="collapse show" id="widget-body-6">
                                        <div className="widget-body">
                                            <ul className="cat-list">
                                                <li>
                                                    <a href="#">Adidas</a>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                    
                                </div> */}
                                {/* End .widget */}
                                {/* <div className="widget widget-color">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-4" role="button" aria-expanded="true" aria-controls="widget-body-4">Color</a>
                                    </h3>
                                    <div className="collapse show" id="widget-body-4">
                                        <div className="widget-body pb-0">
                                            <ul className="config-swatch-list">
                                                <li className="active">
                                                    <a href="#" style={{ backgroundColor: '#000' }} />
                                                </li>
                                                <li>
                                                    <a href="#" style={{ backgroundColor: '#0188cc' }} />
                                                </li>
                                                <li>
                                                    <a href="#" style={{ backgroundColor: '#81d742' }} />
                                                </li>
                                                <li>
                                                    <a href="#" style={{ backgroundColor: '#6085a5' }} />
                                                </li>
                                                <li>
                                                    <a href="#" style={{ backgroundColor: '#eded78' }} />
                                                </li>
                                                <li>
                                                    <a href="#" style={{ backgroundColor: '#ab6e6e' }} />
                                                </li>
                                                <li>
                                                    <a href="#" style={{ backgroundColor: '#809fbf' }} />
                                                </li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                    
                                </div> */}
                                {/* End .widget */}
                                <div className="widget widget-featured">
                                    <h3 className="widget-title">Featured</h3>
                                    <div className="widget-body">
                                        <div className="owl-carousel widget-featured-products">
                                            <div className="featured-col">
                                                <div className="product-default left-details product-widget">
                                                    <figure>
                                                        <a href="product.html">
                                                            <img src={dummyImage1} width={84} height={84} alt="product" />
                                                            <img src={dummyImage1} width={84} height={84} alt="product" />
                                                        </a>
                                                    </figure>
                                                    <div className="product-details">
                                                        <h3 className="product-title"> <a href="product.html">Blue Backpack for
                                                            the Young - S</a> </h3>
                                                        <div className="ratings-container">
                                                            <div className="product-ratings">
                                                                <span className="ratings" style={{ width: '100%' }} />
                                                                {/* End .ratings */}
                                                                <span className="tooltiptext tooltip-top" />
                                                            </div>
                                                            {/* End .product-ratings */}
                                                        </div>
                                                        {/* End .product-container */}
                                                        <div className="price-box">
                                                            <span className="product-price">$49.00</span>
                                                        </div>
                                                        {/* End .price-box */}
                                                    </div>
                                                    {/* End .product-details */}
                                                </div>
                                                <div className="product-default left-details product-widget">
                                                    <figure>
                                                        <a href="product.html">
                                                            <img src={dummyImage1} width={84} height={84} alt="product" />
                                                        </a>
                                                    </figure>
                                                    <div className="product-details">
                                                        <h3 className="product-title"> <a href="product.html">Casual Spring Blue
                                                            Shoes</a> </h3>
                                                        <div className="ratings-container">
                                                            <div className="product-ratings">
                                                                <span className="ratings" style={{ width: '100%' }} />
                                                                {/* End .ratings */}
                                                                <span className="tooltiptext tooltip-top" />
                                                            </div>
                                                            {/* End .product-ratings */}
                                                        </div>
                                                        {/* End .product-container */}
                                                        <div className="price-box">
                                                            <span className="product-price">$49.00</span>
                                                        </div>
                                                        {/* End .price-box */}
                                                    </div>
                                                    {/* End .product-details */}
                                                </div>
                                                <div className="product-default left-details product-widget">
                                                    <figure>
                                                        <a href="product.html">
                                                            <img src={dummyImage1} width={84} height={84} alt="product" />
                                                        </a>
                                                    </figure>
                                                    <div className="product-details">
                                                        <h3 className="product-title"> <a href="product.html">Men Black Gentle
                                                            Belt</a> </h3>
                                                        <div className="ratings-container">
                                                            <div className="product-ratings">
                                                                <span className="ratings" style={{ width: '100%' }} />
                                                                {/* End .ratings */}
                                                                <span className="tooltiptext tooltip-top" />
                                                            </div>
                                                            {/* End .product-ratings */}
                                                        </div>
                                                        {/* End .product-container */}
                                                        <div className="price-box">
                                                            <span className="product-price">$49.00</span>
                                                        </div>
                                                        {/* End .price-box */}
                                                    </div>
                                                    {/* End .product-details */}
                                                </div>
                                            </div>
                                            {/* End .featured-col */}
                                            <div className="featured-col">
                                                <div className="product-default left-details product-widget">
                                                    <figure>
                                                        <a href="product.html">
                                                            <img src={dummyImage1} width={84} height={84} alt="product" />
                                                            <img src={dummyImage1} width={84} height={84} alt="product" />
                                                        </a>
                                                    </figure>
                                                    <div className="product-details">
                                                        <h3 className="product-title"> <a href="product.html">Ultimate 3D
                                                            Bluetooth Speaker</a> </h3>
                                                        <div className="ratings-container">
                                                            <div className="product-ratings">
                                                                <span className="ratings" style={{ width: '100%' }} />
                                                                {/* End .ratings */}
                                                                <span className="tooltiptext tooltip-top" />
                                                            </div>
                                                            {/* End .product-ratings */}
                                                        </div>
                                                        {/* End .product-container */}
                                                        <div className="price-box">
                                                            <span className="product-price">$49.00</span>
                                                        </div>
                                                        {/* End .price-box */}
                                                    </div>
                                                    {/* End .product-details */}
                                                </div>
                                                <div className="product-default left-details product-widget">
                                                    <figure>
                                                        <a href="product.html">
                                                            <img src={dummyImage1} width={84} height={84} alt="product" />
                                                            <img src={dummyImage1} width={84} height={84} alt="product" />
                                                        </a>
                                                    </figure>
                                                    <div className="product-details">
                                                        <h3 className="product-title"> <a href="product.html">Brown Women Casual
                                                            HandBag</a> </h3>
                                                        <div className="ratings-container">
                                                            <div className="product-ratings">
                                                                <span className="ratings" style={{ width: '100%' }} />
                                                                {/* End .ratings */}
                                                                <span className="tooltiptext tooltip-top" />
                                                            </div>
                                                            {/* End .product-ratings */}
                                                        </div>
                                                        {/* End .product-container */}
                                                        <div className="price-box">
                                                            <span className="product-price">$49.00</span>
                                                        </div>
                                                        {/* End .price-box */}
                                                    </div>
                                                    {/* End .product-details */}
                                                </div>
                                                <div className="product-default left-details product-widget">
                                                    <figure>
                                                        <a href="product.html">
                                                            <img src={dummyImage1} width={84} height={84} alt="product" />
                                                            <img src={dummyImage1} width={84} height={84} alt="product" />
                                                        </a>
                                                    </figure>
                                                    <div className="product-details">
                                                        <h3 className="product-title"> <a href="product.html">Circled Ultimate
                                                            3D Speaker</a> </h3>
                                                        <div className="ratings-container">
                                                            <div className="product-ratings">
                                                                <span className="ratings" style={{ width: '100%' }} />
                                                                {/* End .ratings */}
                                                                <span className="tooltiptext tooltip-top" />
                                                            </div>
                                                            {/* End .product-ratings */}
                                                        </div>
                                                        {/* End .product-container */}
                                                        <div className="price-box">
                                                            <span className="product-price">$49.00</span>
                                                        </div>
                                                        {/* End .price-box */}
                                                    </div>
                                                    {/* End .product-details */}
                                                </div>
                                            </div>
                                            {/* End .featured-col */}
                                        </div>
                                        {/* End .widget-featured-slider */}
                                    </div>
                                    {/* End .widget-body */}
                                </div>
                                {/* End .widget */}
                                {/* <div className="widget widget-block">
                                    <h3 className="widget-title">Custom HTML Block</h3>
                                    <h5>This is a custom sub-title.</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras non placerat mi. Etiam
                                        non tellus </p>
                                </div> */}
                                {/* End .widget */}
                            </div>
                            {/* End .sidebar-wrapper */}
                        </aside>
                        {/* End .col-lg-3 */}
                    </div>
                    {/* End .row */}
                </div>
                {/* End .container */}
            </main>
        </>
    )
}

export default ProductsByColor;