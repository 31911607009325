import React,{ useState } from 'react'
import Config from "../../config.json"
import axios from "axios";

export default function Contactus() {

  const addQueryURL = process.env.REACT_APP_BASE_URL + 'add-query';

  const [message,setMessage] = useState('');
  const [formField,setFormField] = useState({
    name:"",
    email: "",
    message:""
  })

  const handleInputChange = (e)=>{
    setFormField({ ...formField,[e.target.name]:e.target.value});
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    const formData = { 
      name:formField.name,
      email:formField.email,
      message:formField.message
    }
    axios.post(addQueryURL,formData).then(result=>{
      if(result.data.success==1){
        setMessage(result.data.message)
        setFormField({
          name:'',
          email:'',
          message:''
        })
      }
      else{
        setMessage(result.data.message)
      }
    }).catch(err=>{
    })
  }
  return (
    <main className="main">
      {/* <div id="map" /> */}
      {/* <iframe src="{video}" width={"100%"} height={450} style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      <div className="container-fluid px-0">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 tech-video">
            <video src={`${Config.REACT_BASE_URL}/custom/assets/video/intro.mp4`} style={{    width: "115%"}} autoplay = "autoplay" loop = "loop" muted preload="auto"/>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div className="row custom-row-address-1">
              <div className="col-sm-6 col-lg-6">
                  <div className="contact feature-box text-center">
                    <i className="fa fa-map-marker-alt"></i>
                    <div className="contact feature-box-content">
                      <h3>Adres</h3>
                      <h5>
                        &nbsp;&nbsp;&nbsp;Unity Trading B.V.<br/>
                        Abberdaan 210,<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        1046AB Amsterdam,<br/>
                        KVK: 80337422,<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        BTW: NL861636168B01
                      </h5>
                    </div>
                  </div>
              </div>
              <div className="col-sm-6 col-lg-6">
                <div className="contact feature-box text-center">
                  <i className="fa fa-mobile-alt" />
                  <div className="contact feature-box-content">
                    <h3>Tel</h3>
                    <h5>(085) - 016 13 60</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row custom-row-address-2">
              <div className="col-sm-6 col-lg-6">
                  <div className="contact feature-box text-center">
                    <i className="far fa-envelope" />
                    <div className="contact feature-box-content">
                      <h3>E-mail Adres</h3>
                      <h5>info@techbyte.nl</h5>
                    </div>
                  </div>
              </div>
              <div className="col-sm-6 col-lg-6">
                  <div className="contact feature-box text-center">
                    <i className="far fa-calendar-alt" />
                    <div className="contact feature-box-content">
                      <h3>Werkdagen/uren</h3>
                      <h5>Ma - Vrij/9:00 - 17:00</h5>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid contact-us-container">
        <div className="contact-info">
        </div>
        <div className="row">
        <div className='contact_image col-lg-6 col-md-12'>
           <img src={`${Config.REACT_BASE_URL}/custom/assets/images/undraw-contact.svg`} alt=''/>
          </div>
          <div className="col-lg-6 col-md-12">
            <h2 className="mt-6 mb-2">Stuur ons een bericht</h2>
            <span style={{color:"red"}}>{message ? message : ''}</span>
            
            <form className="mb-0" action="#">
              <div className="form-group">
                <label className="mb-1" htmlFor="contact-name">Naam
                  <span className="required">*</span></label>
                <input type="text" className="form-control" id="contact-name" name="name" required value={formField.name} onChange={handleInputChange}/>
              </div>
              <div className="form-group">
                <label className="mb-1" htmlFor="contact-email">Jouw E-mail
                  <span className="required">*</span></label>
                <input type="email" className="form-control" id="contact-email" name="email" required value={formField.email} onChange={handleInputChange}/>
              </div>
              <div className="form-group">
                <label className="mb-1" htmlFor="contact-message">Jouw Bericht
                  <span className="required">*</span></label>
                <textarea cols={30} rows={1} id="contact-message" className="form-control" name="message" required defaultValue={""} value={formField.message} onChange={handleInputChange}/>
              </div>
              <div className="form-footer mb-0">
                <button type="submit" className="btn btn-dark font-weight-normal" onClick={handleSubmit}>
                  Stuur
                </button>
              </div>
            </form>
          </div>
          
          <div className="col-lg-12">
            <h2 className="ml-5 mt-6 mb-5">Veelgestelde Vragen</h2>
            <div id="accordion">
              <div className="card card-accordion" style={{maxWidth:"100%"}}>
                <a className="card-header collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                  Bieden jullie kortingen aan voor bulkbestellingen?
                </a>
                <div id="collapseTwo" className="collapse" data-parent="#accordion">
                  <p>
                    Ja, wij bieden aantrekkelijke kortingen voor bulkbestellingen. Neem contact op met ons verkoopteam voor een gepersonaliseerde offerte op basis van uw behoeften.
                  </p>
                </div>
              </div>
              <div className="card card-accordion" style={{maxWidth:"100%"}}>
                <a className="card-header collapsed" href="#" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                  Hoe kan ik een zakelijke account aanmaken?
                </a>
                <div id="collapseThree" className="collapse" data-parent="#accordion">
                  <p>
                  Om een zakelijke account aan te maken, klikt u op de ‘Klant Worden’  Vul vervolgens het registratieformulier in en wij zullen uw aanvraag zo snel mogelijk verwerken.
                  </p>
                </div>
              </div>
              <div className="card card-accordion" style={{maxWidth:"100%"}}>
                <a className="card-header collapsed" href="#" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseThree">
                Welke betalingsmethoden accepteren jullie?
                </a>
                <div id="collapseFour" className="collapse" data-parent="#accordion">
                  <p>
                  Wij accepteren verschillende betalingsmethoden, waaronder bankoverschrijving en iDEAL. Voor grotere bestellingen kunnen wij ook facturering en andere betalingsopties bespreken.
                  </p>
                </div>
              </div>
              <div className="card card-accordion" style={{maxWidth:"100%"}}>
                <a className="card-header collapsed" href="#" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseThree">
                Wat zijn de levertijden voor bestellingen?
                </a>
                <div id="collapseFive" className="collapse" data-parent="#accordion">
                  <p>De levertijden zijn afhankelijk van het product en de bestemming. Voorraadartikelen worden meestal binnen 1-2 werkdagen verzonden. Voor specifieke levertijden kunt u contact opnemen met onze klantenservice.	</p>
                </div>
              </div>

              <div className="card card-accordion" style={{maxWidth:"100%"}}>
                <a className="card-header collapsed" href="#" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapseThree">
                Wat is jullie retourbeleid?
                </a>
                <div id="collapse6" className="collapse" data-parent="#accordion">
                  <p>Ons retourbeleid voor zakelijke klanten biedt de mogelijkheid om defecte of verkeerd geleverde producten binnen 14 dagen na ontvangst te retourneren. Voor meer details over het retourproces kunt u contact opnemen met de klantenservice.	</p>
                </div>
              </div>

              <div className="card card-accordion" style={{maxWidth:"100%"}}>
                <a className="card-header collapsed" href="#" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapseThree">
                Hoe kan ik mijn bestelling volgen?
                </a>
                <div id="collapse7" className="collapse" data-parent="#accordion">
                  <p>Zodra uw bestelling is verzonden, ontvangt u een trackingnummer via e-mail. U kunt dit nummer gebruiken om uw zending te volgen via onze website of de website van de vervoerder.</p>
                </div>
              </div>

              <div className="card card-accordion" style={{maxWidth:"100%"}}>
                <a className="card-header collapsed" href="#" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapseThree">
                  Zijn er garanties op de producten die jullie verkopen?
                </a>
                <div id="collapse8" className="collapse" data-parent="#accordion">
                  <p>Ja, alle producten die wij verkopen komen met een fabrieksgarantie. De duur van de garantie varieert per product. Raadpleeg de productpagina voor specifieke garantie-informatie of neem contact op met onze klantenservice.</p>
                </div>
              </div>

            </div>
          </div> 

        </div>
      </div>
      <div className="mb-8" />
    </main>
  )
}
