import React, { useState, useEffect, useRef } from 'react';
import InvoiceDetails from "./InvoiceDetails";
import { Margin, usePDF } from "react-to-pdf";

export default function InvoicePrint(){

    const buttonStyle = {
        backgroundColor: "#308b7b",
        border: "none",
        color: "white",
        padding: "15px 32px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "16px",
        margin: "4px 2px",
        cursor: "pointer"
    }
    const { toPDF, targetRef } = usePDF({
        filename: "invoice.pdf",
        page: { margin: Margin.MEDIUM }
      });

   
    return(
        <>
            <div className='row'>
                <div class="col-md-12 col-sm-12">
                    <div style={{textAlign: "center",margin:"13px"}}>
                        <button onClick={toPDF} style={buttonStyle}><i class="fa fa-download"></i> Downloaden Factuur</button>
                    </div>
                </div>
            </div>
            <div ref={targetRef}>
                <InvoiceDetails />
            </div>
        </>
    )
}